import React, { useState } from 'react';
import { Mail, AlertCircle, RefreshCw } from 'lucide-react';

interface VerificationStatusProps {
  email: string;
  isVerificationRequired: boolean;
  isVerificationTokenExpired: boolean;
  isVerificationTokenInvalid: boolean;
  onResendVerification: () => Promise<void>;
}

const VerificationStatus: React.FC<VerificationStatusProps> = ({
  email,
  isVerificationRequired,
  isVerificationTokenExpired,
  isVerificationTokenInvalid,
  onResendVerification,
}) => {
  const [isResending, setIsResending] = useState(false);

  const handleResendVerification = async () => {
    setIsResending(true);
    await onResendVerification();
    setIsResending(false);
  };

  if (isVerificationTokenExpired || isVerificationTokenInvalid) {
    return (
      <div className="rounded-lg border border-gray-200 bg-gray-50 p-6">
        <div className="flex items-start space-x-3">
          <AlertCircle className="h-5 w-5 text-gray-600" />
          <div className="flex-1">
            <h3 className="text-lg font-semibold text-gray-900">
              {isVerificationTokenExpired
                ? 'Verification Link Expired'
                : 'Invalid Verification Link'}
            </h3>
            <div className="mt-2">
              <p className="text-gray-600">
                {isVerificationTokenExpired
                  ? `The verification link for ${email} has expired.`
                  : `The verification link is invalid. Please request a new one.`}
              </p>
              <button
                onClick={handleResendVerification}
                disabled={isResending}
                className="mt-4 inline-flex items-center justify-center rounded-lg bg-green-400 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 disabled:opacity-50 transition-colors duration-150"
                type="button"
              >
                <RefreshCw className={`mr-2 h-4 w-4 ${isResending ? 'animate-spin' : ''}`} />
                {isResending ? 'Sending...' : 'Send New Verification Email'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isVerificationRequired) {
    return (
      <div className="rounded-lg bg-gray-50 border border-gray-200 p-6">
        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0">
            <Mail className="h-5 w-5 text-green-400" />
          </div>
          <div className="flex-1">
            <h3 className="text-lg font-semibold text-gray-900">Verify your email address</h3>
            <div className="mt-2 space-y-4">
              <p className="text-gray-600">
                We've sent a verification email to{' '}
                <span className="font-medium text-gray-900">{email}</span>. Please check your inbox
                and verify your account to continue.
              </p>
              <button
                onClick={handleResendVerification}
                disabled={isResending}
                className="inline-flex items-center text-sm text-green-400 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 disabled:opacity-50 transition-colors duration-150"
                type="button"
              >
                <RefreshCw className={`mr-2 h-4 w-4 ${isResending ? 'animate-spin' : ''}`} />
                {isResending
                  ? 'Sending verification email...'
                  : "Didn't receive the email? Send again"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default VerificationStatus;
