import { useContext } from "react";
import { SlideOverContext } from '../context/slideOverContext';


// Hook for using the slide over context

export const useSlideOver = () => {
    const context = useContext(SlideOverContext);
    if (!context) {
        throw new Error('useSlideOver must be used within a SlideOverProvider');
    }
    return context;
};
