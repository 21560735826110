// Footer.tsx
import React from 'react';
import { Github, Twitter, Linkedin, LucideIcon } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';

interface FooterSection {
  title: string;
  links: { name: string; href: string }[];
  requiresAuth?: boolean;
}

interface SocialIcon {
  Icon: LucideIcon;
  href: string;
  label: string;
}

export const Footer: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const currentYear: number = new Date().getFullYear();

  const socialIcons: SocialIcon[] = [
    { Icon: Github, href: 'https://github.com/yourcompany', label: 'GitHub' },
    { Icon: Twitter, href: 'https://twitter.com/yourcompany', label: 'Twitter' },
    { Icon: Linkedin, href: 'https://linkedin.com/company/yourcompany', label: 'LinkedIn' },
  ];

  const footerSections: FooterSection[] = [
    {
      title: 'Product',
      links: [
        { name: 'Features', href: '#features' },
        { name: 'Pricing', href: '#pricing' },
        { name: 'Documentation', href: '#documentation' },
        { name: 'API', href: '#api' },
      ],
      requiresAuth: false,
    },
    {
      title: 'Company',
      links: [
        { name: 'About', href: '#about' },
        //{ name: 'Blog', href: '#blog' },
        //{ name: 'Careers', href: '#careers' },
        { name: 'Contact', href: '#contact' },
      ],
      requiresAuth: false,
    },
    {
      title: 'Resources',
      links: [
        { name: 'Support', href: '#support' },
        //  { name: 'Community', href: '#community' },
        // { name: 'Privacy Policy', href: '#privacy-policy' },
        //{ name: 'Terms of Service', href: '#terms-of-service' },
      ],
      requiresAuth: false,
    },
  ];

  return (
    <footer className="bg-gray-800 text-gray-400">
      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="md:col-span-1">
            <div className="text-2xl font-bold text-white mb-4">
              Releases<span className="text-green-400">.</span>
            </div>
            <p className="text-gray-400 mb-6">
              Streamline your development workflow with our powerful release management platform.
            </p>
            <div className="flex items-center gap-4">
              {socialIcons.map(({ Icon, href, label }, index) => (
                <a
                  key={index}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={label}
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <Icon className="w-5 h-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          {footerSections
            .filter((section) => !section.requiresAuth || isAuthenticated)
            .map((section) => (
              <div key={section.title}>
                <h3 className="font-semibold text-white mb-4">{section.title}</h3>
                <ul className="space-y-3">
                  {section.links.map((link) => (
                    <li key={link.name}>
                      <a
                        href={link.href}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>

        {/* Bottom section */}
        <div className="border-t border-gray-700 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-sm">© {currentYear} Releases. All rights reserved.</p>
            <div className="flex items-center gap-6">
              {['Privacy Policy', 'Terms of Service', 'Cookie Policy'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase().replace(/\s+/g, '-')}`}
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
