import { BackendError } from "../../models/errorTypes";


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createBackendError(data: any, response: Response): BackendError {
  return {
    type: data.type || 'app-store/server-error',
    status: response.status,
    title: data.title || 'Authentication Error',
    details: data.detail || data.message || 'Failed to obtain access token',
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createBackendErrorWithStatus(data: any, status: number): BackendError {
  return {
    type: data.type || 'app-store/server-error',
    status,
    title: data.title || 'Authentication Error',
    details: data.detail || data.message || 'Failed to obtain access token',
  };
}

export function createGenericError(): BackendError {
  return {
    type: 'app-store/server-error',
    status: 500,
    title: 'Unexpected Error',
    details: 'An unexpected error occurred during authentication',
  };
}export const errorMessages: Record<string, string> = {
  'app-store/unauthorized': 'You need to sign in to access this feature. Please log in and try again.',
  'app-store/forbidden': "You don't have permission to perform this action. Please contact your administrator.",
  'app-store/not-found': 'The requested resource could not be found.',
  'app-store/validation-error': 'There was a problem with the data you provided. Please check your input and try again.',
  'app-store/server-error': 'Something went wrong on our end. Please try again later.',
  'app-store/rate-limit': "You've made too many requests. Please wait a moment and try again.",
  'app-store/user-email-already-exists': 'The e-mail is already registered.',
  default: 'An unexpected error occurred. Please try again later.',
};

