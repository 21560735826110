import { TokenEvents, TokenService } from "@/lib/token";
import { useEffect, useState } from "react";

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const tokenService = TokenService.getInstance();
    const tokenEvents = TokenEvents.getInstance();
  
    const checkAuth = () => {
      const authStatus = tokenService.isAuthenticated();
      setIsAuthenticated(authStatus);
    };
  
    useEffect(() => {
      checkAuth();
      const removeListener = tokenEvents.addListener(checkAuth);
      const handleStorageChange = (event: StorageEvent) => {
        if (event.key === tokenService.storageKey) {
          checkAuth();
        }
      };
  
      window.addEventListener('storage', handleStorageChange);
      const interval = setInterval(checkAuth, 60000);
  
      return () => {
        removeListener();
        window.removeEventListener('storage', handleStorageChange);
        clearInterval(interval);
      };
    }, []);
  
    return { isAuthenticated, checkAuth };
  };