import { useState, useEffect } from 'react';
import {
  Calendar,
  Users,
  AlertTriangle,
  Clock,
  CheckCircle,
  Copy,
  Trash2,
  X,
  Link as LinkIcon,
  QrCode,
  File,
} from 'lucide-react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { Build } from '@/models/build';

interface PublicLink {
  publicLinkId: string;
  fileId: string;
  expiration: string;
  downloadLimit: number;
  publicLink: string;
  isActive: boolean;
  accessCount: number;
}

interface BuildFile {
  fileId: string;
  fileName: string;
  fileExtension: string;
  fileSize: number;
  filePath: string;
}

interface PublicLinksProps {
  build: Build;
  projectSlug: string;
  workspaceSlug: string;
}

interface LinkStatus {
  status: 'ACTIVE' | 'DEACTIVATED' | 'EXPIRED' | 'LIMIT REACHED';
  color: string;
}

export const PublicLinks: React.FC<PublicLinksProps> = ({
  build,
  workspaceSlug,
  projectSlug,
}) => {
  const [links, setLinks] = useState<PublicLink[]>([]);
  const [files, setFiles] = useState<BuildFile[]>([]);
  const [copiedLink, setCopiedLink] = useState<string | null>(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showOnlyActive, setShowOnlyActive] = useState(true);
  const [newLinkSettings, setNewLinkSettings] = useState({
    expirationDays: 7,
    downloadLimit: 100,
  });

  useEffect(() => {
    fetchPublicLinks();
    fetchBuildFiles();
  }, [build.buildId]);

  const fetchBuildFiles = async () => {
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${build.buildId}/files`,
        {
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch build files');
      }

      const data = await response.json();
      setFiles(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch build files');
    }
  };

  const fetchPublicLinks = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${build.buildId}/public-links`,
        {
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch public links');
      }

      const data = await response.json();
      setLinks(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const generateQRCode = async (linkId: string) => {
    try {
      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/public/file/${linkId}/qr`, {
        headers: {
          Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to generate QR code');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate QR code');
    }
  };

  const generatePublicLink = async () => {
    if (!selectedFile) {
      setError('Please select a file');
      return;
    }

    try {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + newLinkSettings.expirationDays);

      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/files/${selectedFile}/public-link`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            expirationDate: expirationDate.toISOString(),
            downloadLimit: newLinkSettings.downloadLimit,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to create public link');
      }

      const newLink = await response.json();
      setLinks([...links, newLink]);
      setIsCreatingNew(false);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create link');
    }
  };

  const handleCopyLink = async (url: string, linkId: string) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiedLink(linkId);
      setTimeout(() => setCopiedLink(null), 2000);
    } catch (err) {
      setError('Failed to copy link to clipboard');
    }
  };

  const deactivateLink = async (fileId: string, linkId: string) => {
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/files/${fileId}/public-links/${linkId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to deactivate link');
      }

      await fetchPublicLinks();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to deactivate link');
    }
  };

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatFileSize = (size: number): string => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let fileSize = size;
    let unitIndex = 0;

    while (fileSize >= 1024 && unitIndex < units.length - 1) {
      fileSize /= 1024;
      unitIndex++;
    }

    return `${fileSize.toFixed(1)} ${units[unitIndex]}`;
  };

  const getLinkStatus = (link: PublicLink): LinkStatus => {
    if (!link.isActive) return { status: 'DEACTIVATED', color: 'text-gray-600' };
    if (new Date(link.expiration) < new Date()) return { status: 'EXPIRED', color: 'text-red-600' };
    if (link.accessCount >= link.downloadLimit)
      return { status: 'LIMIT REACHED', color: 'text-orange-600' };
    return { status: 'ACTIVE', color: 'text-green-600' };
  };

  const sortAndFilterLinks = (links: PublicLink[]) => {
    let filteredLinks = [...links];
    if (showOnlyActive) {
      filteredLinks = filteredLinks.filter((link) => getLinkStatus(link).status === 'ACTIVE');
    }
    return filteredLinks.sort((a, b) => {
      const statusA = getLinkStatus(a).status === 'ACTIVE' ? 0 : 1;
      const statusB = getLinkStatus(b).status === 'ACTIVE' ? 0 : 1;
      return statusA - statusB;
    });
  };

  const getFileNameById = (fileId: string): string => {
    const file = files.find((f) => f.fileId === fileId);
    return file ? file.fileName : 'Unknown file';
  };

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 p-4 rounded-xl flex items-center gap-3 text-red-600">
          <AlertTriangle className="w-5 h-5" />
          <p className="text-sm">{error}</p>
        </div>
      )}

      <div className="flex justify-between items-center">
        {!isCreatingNew && (
          <button
            onClick={() => setIsCreatingNew(true)}
            className="bg-green-400 text-white px-4 py-2 text-sm rounded-lg hover:bg-green-500 transition-colors font-medium"
          >
            Create New Link
          </button>
        )}
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="showActive"
            checked={showOnlyActive}
            onChange={(e) => setShowOnlyActive(e.target.checked)}
            className="rounded border-gray-300 text-green-400 focus:ring-green-400"
          />
          <label htmlFor="showActive" className="text-sm text-gray-600">
            Show only active
          </label>
        </div>
      </div>

      {isCreatingNew && (
        <div className="bg-white rounded-lg border border-gray-200 p-4 space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-900">New Public Link</h3>
            <button
              onClick={() => setIsCreatingNew(false)}
              className="p-1 hover:bg-gray-50 rounded-lg transition-colors"
            >
              <X className="w-4 h-4 text-gray-500" />
            </button>
          </div>

          <div className="space-y-4">
            <div className="space-y-1">
              <label className="text-sm font-medium text-gray-700">Select File</label>
              <select
                value={selectedFile}
                onChange={(e) => setSelectedFile(e.target.value)}
                className="w-full text-sm rounded-lg border-gray-300 focus:border-green-400 focus:ring-green-400"
              >
                <option value="">Select a file</option>
                {files.map((file) => (
                  <option key={file.fileId} value={file.fileId}>
                    {file.fileName} ({formatFileSize(file.fileSize)})
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-1">
                <label className="text-sm font-medium text-gray-700">Expiration (days)</label>
                <div className="relative">
                  <Calendar className="absolute left-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                  <input
                    type="number"
                    className="w-full pl-8 text-sm rounded-lg border-gray-300 focus:border-green-400 focus:ring-green-400"
                    value={newLinkSettings.expirationDays}
                    onChange={(e) =>
                      setNewLinkSettings({
                        ...newLinkSettings,
                        expirationDays: parseInt(e.target.value),
                      })
                    }
                    min="1"
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label className="text-sm font-medium text-gray-700">Download Limit</label>
                <div className="relative">
                  <Users className="absolute left-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                  <input
                    type="number"
                    className="w-full pl-8 text-sm rounded-lg border-gray-300 focus:border-green-400 focus:ring-green-400"
                    value={newLinkSettings.downloadLimit}
                    onChange={(e) =>
                      setNewLinkSettings({
                        ...newLinkSettings,
                        downloadLimit: parseInt(e.target.value),
                      })
                    }
                    min="1"
                    max="1000"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={() => setIsCreatingNew(false)}
              className="px-4 py-2 text-sm border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={generatePublicLink}
              className="px-4 py-2 text-sm bg-green-400 text-white rounded-lg hover:bg-green-500 transition-colors"
            >
              Generate Link
            </button>
          </div>
        </div>
      )}

      <div className="space-y-3">
        {isLoading ? (
          <div className="flex justify-center py-6">
            <div className="w-6 h-6 border-b-2 border-green-400 rounded-full animate-spin"></div>
          </div>
        ) : (
          sortAndFilterLinks(links).map((link) => {
            const { status } = getLinkStatus(link);
            const isActive = status === 'ACTIVE';
            const fileName = getFileNameById(link.fileId);

            return (
              <div
                key={link.fileId}
                className={`bg-white rounded-lg border ${
                  isActive ? 'border-green-200' : 'border-gray-200'
                } p-4 space-y-3`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-medium ${
                        status === 'ACTIVE'
                          ? 'bg-green-100 text-green-600'
                          : status === 'EXPIRED'
                          ? 'bg-red-100 text-red-600'
                          : status === 'LIMIT REACHED'
                          ? 'bg-orange-100 text-orange-600'
                          : 'bg-gray-100 text-gray-600'
                      }`}
                    >
                      {status}
                    </span>
                    <span className="flex items-center text-sm text-gray-600">
                      <File className="w-4 h-4 mr-1" />
                      {fileName}
                    </span>
                  </div>
                  {link.isActive && (
                    <div className="flex gap-1">
                      <button
                        onClick={() => handleCopyLink(link.publicLink, link.fileId)}
                        className="p-1.5 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                        title="Copy link"
                      >
                        {copiedLink === link.fileId ? (
                          <CheckCircle className="w-4 h-4 text-green-400" />
                        ) : (
                          <Copy className="w-4 h-4 text-gray-500" />
                        )}
                      </button>
                      <button
                        onClick={() => generateQRCode(link.publicLinkId)}
                        className="p-1.5 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                        title="Generate QR code"
                      >
                        <QrCode className="w-4 h-4 text-gray-500" />
                      </button>
                      <button
                        onClick={() => deactivateLink(link.fileId, link.publicLinkId)}
                        className="p-1.5 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                        title="Deactivate link"
                      >
                        <Trash2 className="w-4 h-4 text-red-500" />
                      </button>
                    </div>
                  )}
                </div>

                <div className="bg-gray-50 rounded-lg p-2">
                  <div className="flex items-center text-sm text-gray-600 break-all">
                    <LinkIcon className="w-4 h-4 mr-2 flex-shrink-0" />
                    {link.publicLink}
                  </div>
                </div>

                <div className="flex flex-wrap gap-3 text-xs text-gray-600">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-1 text-gray-400" />
                    Expires: {formatDate(link.expiration)}
                  </div>
                  <div className="flex items-center">
                    <Users className="w-4 h-4 mr-1 text-gray-400" />
                    Downloads: {link.accessCount} / {link.downloadLimit}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {error && (
        <div className="flex justify-end">
          <button
            onClick={fetchPublicLinks}
            className="text-sm text-green-400 hover:text-green-500 transition-colors"
          >
            Retry
          </button>
        </div>
      )}
    </div>
  );
};

export default PublicLinks;