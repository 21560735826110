
// Define possible panel types

export enum SlideOverType {
    NONE = 'NONE',
    BUILD_FILES = 'BUILD_FILES',
    EDIT_BUILD = 'EDIT_BUILD',
    PUBLIC_LINKS = 'PUBLIC_LINKS',
    BUILD_UPLOAD = 'BUILD_UPLOAD',
    PROJECT_EDIT = 'PROJECT_EDIT',
    PROJECT_CREATE = 'PROJECT_CREATE',
    WORKSPACE_ADD = 'WORKSPACE_ADD',
    WORKSPACE_EDIT = 'WORKSPACE_EDIT',
}
