import { useState } from 'react';
import { Check, Globe, Server } from 'lucide-react';

const PricingPage = () => {
  const [isHovered, setIsHovered] = useState(null);

  const comparisonFeatures = [
    { name: 'Build Storage', free: 'Self-hosted S3', pro: 'Multi-region + On-premises' },
    { name: 'Number of Projects', free: '1', pro: 'Unlimited' },
    { name: 'Team Members', free: '2', pro: 'Unlimited' },
    { name: 'Build History', free: '30 days', pro: 'Unlimited' },
    { name: 'CI/CD Integration', free: 'Basic', pro: 'Advanced' },
    { name: 'Audit Logs', free: 'Basic', pro: 'Advanced' },
    { name: 'SSO', free: '✕', pro: '✓' },
    { name: 'Priority Support', free: '✕', pro: '✓' },
    { name: 'Custom Integrations', free: '✕', pro: '✓' },
    { name: 'Security Features', free: 'Basic', pro: 'Advanced' },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-6 pt-24 pb-16">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-5xl font-bold text-navy-900 mb-6">Simple pricing for any team</h1>
          <p className="text-xl text-gray-600 mb-12">
            Whether you're hosting builds for a personal project or managing enterprise releases, we
            have a plan that matches your needs.
          </p>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="max-w-7xl mx-auto px-6 pb-24">
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {/* Free Plan */}
          <div
            className={`relative overflow-hidden rounded-2xl bg-white border border-gray-200 transition-all duration-300 ${
              isHovered === 0 ? 'scale-[1.02]' : 'scale-100'
            }`}
            onMouseEnter={() => setIsHovered(null)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <div className="p-8">
              <div className="inline-flex items-center justify-center rounded-lg p-2 mb-4 bg-green-50">
                <Server className="w-6 h-6 text-green-500" />
              </div>
              <h3 className="text-2xl font-bold mb-2">Free</h3>
              <p className="text-gray-600 mb-6">Perfect for personal projects</p>

              <div className="mb-8">
                <div className="text-3xl font-bold mb-1">$0</div>
                <div className="text-gray-600">forever</div>
              </div>

              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-600">
                  <Check className="w-5 h-5 text-green-400 mr-2" />
                  Self-hosted S3 storage
                </li>
                <li className="flex items-center text-gray-600">
                  <Check className="w-5 h-5 text-green-400 mr-2" />1 workspace and project
                </li>
                <li className="flex items-center text-gray-600">
                  <Check className="w-5 h-5 text-green-400 mr-2" />2 team members
                </li>
                <li className="flex items-center text-gray-600">
                  <Check className="w-5 h-5 text-green-400 mr-2" />
                  Basic build analytics
                </li>
              </ul>

              <button className="w-full py-4 px-6 rounded-xl font-medium bg-navy-900 text-white hover:bg-navy-800 transition-all duration-200">
                Start for free
              </button>
            </div>
          </div>

          {/* Pro Plan */}
          <div
            className={`relative overflow-hidden rounded-2xl bg-navy-900 text-white transition-all duration-300 ${
              isHovered === 1 ? 'scale-[1.02]' : 'scale-100'
            }`}
            onMouseEnter={() => setIsHovered(null)}
            onMouseLeave={() => setIsHovered(null)}
          >
            <div className="absolute top-4 right-4 px-3 py-1 bg-green-400 text-white text-sm font-medium rounded-full">
              Popular
            </div>
            <div className="p-8">
              <div className="inline-flex items-center justify-center rounded-lg p-2 mb-4 bg-green-400/10">
                <Globe className="w-6 h-6 text-green-400" />
              </div>
              <h3 className="text-2xl font-bold mb-2">Pro</h3>
              <p className="text-gray-400 mb-6">For growing teams and enterprises</p>

              <div className="mb-8">
                <div className="text-3xl font-bold mb-1">Contact us</div>
                <div className="text-gray-400">custom pricing</div>
              </div>

              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <Check className="w-5 h-5 text-green-400 mr-2" />
                  Multi-region storage
                </li>
                <li className="flex items-center text-gray-300">
                  <Check className="w-5 h-5 text-green-400 mr-2" />
                  Unlimited projects & members
                </li>
                <li className="flex items-center text-gray-300">
                  <Check className="w-5 h-5 text-green-400 mr-2" />
                  Advanced security features
                </li>
                <li className="flex items-center text-gray-300">
                  <Check className="w-5 h-5 text-green-400 mr-2" />
                  Priority support
                </li>
              </ul>

              <button className="w-full py-4 px-6 rounded-xl font-medium bg-green-400 text-white hover:bg-green-500 transition-all duration-200">
                Contact sales
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Feature Comparison */}
      <div className="border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-6 py-24">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-navy-900 mb-4">Compare plans</h2>
            <p className="text-xl text-gray-600">
              See all features and choose the best plan for your needs
            </p>
          </div>

          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-200">
                    <th className="text-left py-4 px-6 text-gray-600">Features</th>
                    <th className="text-center py-4 px-6 font-bold">Free</th>
                    <th className="text-center py-4 px-6 font-bold">Pro</th>
                  </tr>
                </thead>
                <tbody>
                  {comparisonFeatures.map((feature, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="py-4 px-6 text-gray-600">{feature.name}</td>
                      <td className="text-center py-4 px-6">{feature.free}</td>
                      <td className="text-center py-4 px-6 font-medium">{feature.pro}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-6 py-24">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-navy-900 mb-4">Frequently asked questions</h2>
            <p className="text-xl text-gray-600">Have questions? We're here to help</p>
          </div>

          <div className="max-w-3xl mx-auto grid gap-8">
            {[
              {
                q: 'Can I use my own S3 storage with the free plan?',
                a: 'Yes, the free plan allows you to use your own S3-compatible storage solution.',
              },
              {
                q: 'How does the Pro plan pricing work?',
                a: 'Pro plan pricing is customized based on your needs, storage requirements, and team size.',
              },
              {
                q: 'Can I upgrade from Free to Pro later?',
                a: "Yes, you can upgrade to Pro at any time and we'll help migrate your data.",
              },
              {
                q: 'What kind of support is included?',
                a: 'Free includes community support, while Pro includes priority email and chat support.',
              },
            ].map((item, index) => (
              <div key={index} className="bg-white rounded-xl border border-gray-200 p-6">
                <h3 className="text-lg font-bold mb-2">{item.q}</h3>
                <p className="text-gray-600">{item.a}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-6 py-24">
          <div className="bg-navy-900 rounded-2xl p-12 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to get started?</h2>
            <p className="text-xl text-gray-300 mb-8">
              Join thousands of developers managing their builds with Releases
            </p>
            <div className="flex justify-center gap-4">
              <button className="py-4 px-8 rounded-xl font-medium bg-green-400 text-white hover:bg-green-500 transition-all duration-200">
                Start for free
              </button>
              <button className="py-4 px-8 rounded-xl font-medium border border-gray-700 text-white hover:bg-white/5 transition-all duration-200">
                Contact sales
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
