import {
  SiAndroid,
  SiApple,
  SiAmazon,
  SiLinux,
  SiGoogleplay,
  SiAppstore,
  SiHtml5,
  SiMacos,
  Si7zip
} from '@icons-pack/react-simple-icons';

export type PlatformType =
  | 'APPLICATION'
  | 'PACKAGE'
  | 'JAR'
  | 'ZIP'
  | 'WEB'
  | 'HTML5'
  | 'WINDOWS'
  | 'MACOS'
  | 'LINUX'
  | 'ANDROID'
  | 'IOS'
  | 'GOOGLE_PLAY'
  | 'APP_STORE'
  | 'AMAZON_APPSTORE'
  | 'OTHER';

export const PLATFORM_TYPES: PlatformType[] = [
  'APPLICATION',
  'PACKAGE',
  'JAR',
  'ZIP',
  'WEB',
  'HTML5',
  'WINDOWS',
  'MACOS',
  'LINUX',
  'ANDROID',
  'IOS',
  'GOOGLE_PLAY',
  'APP_STORE',
  'AMAZON_APPSTORE',
  'OTHER',
];


// Microsoft removed some https://github.com/simple-icons/simple-icons/pull/10019/files#diff-13dd25edfa890d0982162cb795f9e8ded1cf8ce4c9de66f195da4d3878b4e7d1
const MicrosoftLogo = () => (<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><path d="M0 0v11.408h11.408V0zm12.594 0v11.408H24V0zM0 12.594V24h11.408V12.594zm12.594 0V24H24V12.594z" /> </svg>);

export const getPlatformIcon = (platform: string): React.ReactNode => {
  const iconMap: Record<PlatformType, React.ComponentType | (() => React.ReactNode)> = {
    APPLICATION: MicrosoftLogo,
    PACKAGE: () => <Si7zip />,
    JAR: () => <Si7zip />,
    ZIP: () => <Si7zip />,
    WEB: () => <SiHtml5 />,
    HTML5: () => <SiHtml5 />,
    WINDOWS: () => <MicrosoftLogo />,
    MACOS: () => <SiMacos />,
    LINUX: () => <SiLinux />,
    ANDROID: () => <SiAndroid />,
    IOS: () => <SiApple />,
    GOOGLE_PLAY: () => <SiGoogleplay />,
    APP_STORE: () => <SiAppstore />,
    AMAZON_APPSTORE: () => <SiAmazon />,
    OTHER: () => <Si7zip />
  };

  const IconComponent = iconMap[platform as PlatformType] || iconMap.OTHER;
  return <IconComponent />
};