import { EndpointConfig, TokenService } from '@/lib/token';
import { useState } from 'react';
import { BuildFile } from '../BuildFileList';

interface UseFileDownloadsReturn {
    downloadFile: (file: BuildFile) => Promise<void>;
    isDownloading: boolean;
    error: Error | null;
}

export const useFileDownloads = (
    workspaceSlug: string,
    projectSlug: string
): UseFileDownloadsReturn => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const downloadFile = async (file: BuildFile) => {
        setIsDownloading(true);
        setError(null);

        try {
            // First, get the signed URL
            const response = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/file/${file.fileId}`,
                {
                    headers: {
                        Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to get download URL: ${response.statusText}`);
            }

            const signedUrl = await response.text();

            // Open the signed URL in a new tab
            // This will show the browser's native download progress
            window.open(signedUrl, '_blank');

        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to download file'));
            console.error('Error downloading file:', err);
        } finally {
            setIsDownloading(false);
        }
    };

    return {
        downloadFile,
        isDownloading,
        error,
    };
};