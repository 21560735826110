// BuildTable.tsx
import React, { useState } from 'react';
import { ChevronDown, ChevronRight, LinkIcon, Copy, Edit2, Trash2, Folder } from 'lucide-react';
import { Build } from '@/models/build';
import { useFormatters } from '@/hooks/useFormatters';
import { format } from 'date-fns';
import { getPlatformIcon } from '@/models/platformType';

export interface BuildTableProps {
    builds: Build[];
    onCopyLink?: (buildId: string) => void;
    onEditBuild?: (build: Build) => void;
    onDeleteBuild?: (buildId: string) => void;
    onShareBuild?: (build: Build) => void;
    onTagClick?: (tag: string) => void;
    onViewFiles?: (build: Build) => void;
    className?: string;
}

export const getStatusColor = (status: string): string => {
    const colors: Record<string, string> = {
        SUCCESS: 'bg-green-100 text-green-600',
        FAILURE: 'bg-red-100 text-red-600',
        PENDING: 'bg-yellow-100 text-yellow-600',
        IN_PROGRESS: 'bg-blue-100 text-blue-600',
        CANCELED: 'bg-gray-100 text-gray-600'
    };
    return colors[status] || 'bg-gray-100 text-gray-600';
};
const BuildTable: React.FC<BuildTableProps> = ({
    builds,
    onCopyLink,
    onEditBuild,
    onDeleteBuild,
    onShareBuild,
    onTagClick,
    onViewFiles,
    className = '',
}) => {
    const [expandedBuildId, setExpandedBuildId] = useState<string | null>(null);

    const { formatDuration, formatRelativeTime, formatFileSize } = useFormatters();

    const toggleExpandRow = (buildId: string) => {
        setExpandedBuildId(expandedBuildId === buildId ? null : buildId);
    };

    const handleAction = (
        e: React.MouseEvent,
        action: (build: Build) => void,
        build: Build
    ) => {
        e.stopPropagation();
        action(build);
    };

    if (builds.length === 0) {
        return (
            <div className={`w-full overflow-x-auto ${className}`}>
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="w-8 px-6 py-3"></th>
                            <th className="w-24 px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Build</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Version / Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Type</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Size</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Created</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={8}>
                                <div className="flex flex-col items-center justify-center text-gray-500 p-8">
                                    <div className="w-16 h-16 mb-4">
                                        <svg className="w-full h-full" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            />
                                            <path
                                                d="M8 12h8M12 8v8"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </div>
                                    <p>No results found</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className={`w-full overflow-x-auto ${className}`}>
            <table className="min-w-full bg-white">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="w-8 px-6 py-3"></th>
                        <th className="w-24 px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Build</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Version / Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Stream</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Size</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Created</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-navy-200 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {builds.map((build) => (
                        <React.Fragment key={build.buildId}>
                            <tr
                                onClick={() => toggleExpandRow(build.buildId)}
                                className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                            >
                                <td className="px-6 py-4">
                                    {expandedBuildId === build.buildId ?
                                        <ChevronDown className="w-5 h-5 text-gray-500" /> :
                                        <ChevronRight className="w-5 h-5 text-gray-500" />
                                    }
                                </td>
                                <td className="px-6 py-4">
                                    <div className="relative w-16 h-16 flex items-center justify-center">
                                        {getPlatformIcon(build.platform)}
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="font-medium text-navy-200">{build.version}</div>
                                    <div className="text-sm text-gray-500 mt-1">{build.buildName}</div>
                                    <div className="text-xs text-gray-500 mt-1">
                                        {build.commitHash.substring(0, 7)} / {build.branchName}
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-700">{build.buildType}</td>
                                <td className="px-6 py-4">
                                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(build.buildStatus)}`}>
                                        {build.buildStatus}
                                    </span>
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-700">{formatFileSize(build.buildSize)}</td>
                                <td className="px-6 py-4">
                                    <div className="text-sm text-gray-700">{format(new Date(build.createdAt), 'MMM dd, yyyy hh:mm a')}</div>
                                    <div className="text-xs text-gray-500">{formatRelativeTime(new Date(build.createdAt))}</div>
                                </td>
                                <td className="px-6 py-4" onClick={(e) => e.stopPropagation()}>
                                    <div className="flex items-center space-x-2">
                                        {onShareBuild && (
                                            <button
                                                className="p-1 text-gray-500 hover:text-gray-700 rounded border border-gray-300 hover:bg-gray-50"
                                                onClick={(e) => handleAction(e, onShareBuild, build)}
                                            >
                                                <LinkIcon className="w-4 h-4" />
                                            </button>
                                        )}
                                        {onCopyLink && (
                                            <button
                                                className="p-1 text-gray-500 hover:text-gray-700 rounded border border-gray-300 hover:bg-gray-50"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onCopyLink(build.buildId);
                                                }}
                                            >
                                                <Copy className="w-4 h-4" />
                                            </button>
                                        )}
                                        {onViewFiles && (
                                            <button
                                                className="p-1 text-gray-500 hover:text-gray-700 rounded border border-gray-300 hover:bg-gray-50"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onViewFiles(build);
                                                }}
                                            >
                                                <Folder className="w-4 h-4" />
                                            </button>
                                        )}
                                        {onEditBuild && (
                                            <button
                                                className="p-1 text-gray-500 hover:text-gray-700 rounded border border-gray-300 hover:bg-gray-50"
                                                onClick={(e) => handleAction(e, onEditBuild, build)}
                                            >
                                                <Edit2 className="w-4 h-4" />
                                            </button>
                                        )}
                                        {onDeleteBuild && (
                                            <button
                                                className="p-1 text-gray-500 hover:text-gray-700 rounded border border-gray-300 hover:bg-gray-50"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDeleteBuild(build.buildId);
                                                }}
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            {expandedBuildId === build.buildId && (
                                <tr>
                                    <td colSpan={8} className="bg-gray-50 p-0">
                                        <div className="grid grid-cols-2 gap-8 px-8 py-4">
                                            <div>
                                                <div className="mb-8">
                                                    <h3 className="text-md text-navy-200 mb-4">Build Information</h3>
                                                    <dl className="grid grid-cols-1 gap-4">
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Platform</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.platform || '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Architecture</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.platformArchitecture || '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Target Platforms</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.targetPlatforms || '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Duration</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.buildDuration && build.buildDuration > 0 ? formatDuration(build.buildDuration) : '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Commit</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.commitHash || '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Branch</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.branchName || '-'}</dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                                <div>
                                                    <h3 className="text-md text-navy-200 mb-4">Upload Details</h3>
                                                    <dl className="grid grid-cols-1 gap-4">
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Hostname</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.hostname || '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">Uploader</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.uploaderName || '-'}</dd>
                                                        </div>
                                                        <div>
                                                            <dt className="text-sm font-medium text-gray-500">SDK Version</dt>
                                                            <dd className="mt-1 text-sm text-gray-700">{build.uploaderSdkVersion || '-'}</dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-8">
                                                    <h3 className="text-md text-navy-200 mb-4">Description</h3>
                                                    <p className="text-sm text-gray-700 whitespace-pre-wrap">
                                                        {build.description || '-'}
                                                    </p>
                                                </div>
                                                {build.releaseNotes && (
                                                    <div className="mb-8 max-w-lg">
                                                        <h3 className="text-md text-navy-200 mb-4">Release Notes</h3>
                                                        <div className="bg-white rounded-lg border border-gray-200 p-4">
                                                            <pre className="text-sm text-gray-700 whitespace-pre-wrap">
                                                                {build.releaseNotes}
                                                            </pre>
                                                        </div>
                                                    </div>
                                                )}
                                                <div>
                                                    <h3 className="text-md text-navy-200 mb-4">Tags</h3>
                                                    <div className="flex flex-wrap gap-2">
                                                        {build.tags.map((tag) => (
                                                            <span
                                                                key={tag}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onTagClick?.(tag);
                                                                }}
                                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-600 cursor-pointer hover:bg-green-200"
                                                            >
                                                                {tag}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BuildTable;