import { useState } from 'react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { BuildDetails } from '@/models/buildDetails';

interface UseBuildMutationsProps {
    workspaceSlug?: string;
    projectSlug?: string;
    onSuccess?: () => void;
}

interface UseBuildMutationsReturn {
    isLoading: boolean;
    error: Error | null;
    deleteBuild: (buildId: string) => Promise<void>;
    editBuild: (buildId: string, buildDetails: BuildDetails) => Promise<void>;
    clearError: () => void;
}

export function useBuildMutations({
    workspaceSlug,
    projectSlug,
    onSuccess,
}: UseBuildMutationsProps): UseBuildMutationsReturn {
    const [isLoading, setIsSubmitting] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const clearError = () => setError(null);

    const deleteBuild = async (buildId: string) => {
        if (!workspaceSlug || !projectSlug) return;

        if (!confirm('Are you sure you want to delete this build? This action cannot be undone.')) {
            return;
        }

        setIsSubmitting(true);
        clearError();

        try {
            const response = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${buildId}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                    },
                }
            );

            if (!response.ok) throw new Error('Failed to delete build');

            onSuccess?.();
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An error occurred while deleting the build'));
            console.error('Error deleting build:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const editBuild = async (buildId: string, buildDetails: BuildDetails) => {
        if (!workspaceSlug || !projectSlug) return;

        setIsSubmitting(true);
        clearError();

        try {
            const response = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${buildId}`,
                {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(buildDetails),
                }
            );

            if (!response.ok) throw new Error('Failed to update build');

            onSuccess?.();
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An error occurred while updating the build'));
            console.error('Error updating build:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return {
        isLoading,
        error,
        deleteBuild,
        editBuild,
        clearError,
    };
}