import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { FileUploadStatus } from '../../features/builds/types';
import { BuildDetails, defaultBuildDetails } from '@/models/buildDetails';
import BuildForm from '../../features/builds/BuildForm';
import FileUploader from '../../features/builds/FileUploader';

interface BuildUploaderProps {
  workspaceSlug: string;
  projectSlug: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

const StreamlinedBuildUploader: React.FC<BuildUploaderProps> = ({
  workspaceSlug,
  projectSlug,
  onSuccess,
  onError,
}) => {
  const [fileStatuses, setFileStatuses] = useState<FileUploadStatus[]>([]);
  const [buildDetails, setBuildDetails] = useState<BuildDetails>(defaultBuildDetails);
  const [currentStep, setCurrentStep] = useState<'details' | 'upload'>('details');
  const [isAllComplete, setIsAllComplete] = useState(false);

  useEffect(() => {
    if (fileStatuses.length > 0) {
      const allComplete = fileStatuses.every((status) => status.status === 'success');
      const anyError = fileStatuses.some((status) => status.status === 'error');
      const anyUploading = fileStatuses.some((status) => status.status === 'uploading');

      if (allComplete && !anyError && !anyUploading) {
        setIsAllComplete(true);
        onSuccess?.();
      }
    }
  }, [fileStatuses, onSuccess]);

  const addFiles = (newFiles: File[]) => {
    setFileStatuses((prev) => [
      ...prev,
      ...newFiles.map((file) => ({
        file,
        progress: 0,
        status: 'idle' as const,
      })),
    ]);
  };

  const removeFile = (fileToRemove: File) => {
    setFileStatuses((prev) => prev.filter((status) => status.file !== fileToRemove));
  };

  const updateFileStatus = (file: File, updates: Partial<FileUploadStatus>) => {
    setFileStatuses((prev) =>
      prev.map((status) => (status.file === file ? { ...status, ...updates } : status))
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (buildDetails.version.trim() && buildDetails.buildName.trim() &&
      buildDetails.platform && buildDetails.buildType) {
      setCurrentStep('upload');
    }
  };

  const uploadAllFiles = async () => {
    setIsAllComplete(false);
    const totalSize = fileStatuses.reduce((sum, status) => sum + status.file.size, 0);

    try {
      const buildResponse = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
          body: JSON.stringify({
            ...buildDetails,
            buildSize: totalSize,
            buildStatus: 'SUCCESS',
            buildDuration: 0,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          }),
        }
      );

      if (!buildResponse.ok) throw new Error('Failed to create build');
      const buildData = await buildResponse.json();

      await Promise.all(
        fileStatuses
          .filter((status) => status.status === 'idle' || status.status === 'error')
          .map(async ({ file }) => {
            try {
              updateFileStatus(file, { status: 'uploading', progress: 0 });

              const signResponse = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${buildData.buildId}/sign`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                  },
                  body: JSON.stringify({ filename: file.name }),
                }
              );

              if (!signResponse.ok) throw new Error('Failed to get upload URL');
              const signData = await signResponse.json();
              updateFileStatus(file, { progress: 50 });

              const formData = new FormData();
              formData.append('file', file);
              const uploadResponse = await fetch(signData.url, {
                method: 'PUT',
                body: formData,
              });

              if (!uploadResponse.ok) throw new Error('Failed to upload file');
              updateFileStatus(file, { progress: 75 });

              const confirmResponse = await fetch(
                `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${buildData.buildId}/upload`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
                  },
                  body: signData.signedToken,
                }
              );

              if (!confirmResponse.ok) throw new Error('Failed to confirm upload');
              updateFileStatus(file, { status: 'success', progress: 100 });
            } catch (error) {
              updateFileStatus(file, {
                status: 'error',
                errorMessage: error instanceof Error ? error.message : 'Upload failed',
                progress: 0,
              });
              onError?.(error instanceof Error ? error : new Error('Upload failed'));
            }
          })
      );
    } catch (error) {
      fileStatuses.forEach(({ file }) => {
        updateFileStatus(file, {
          status: 'error',
          errorMessage: 'Failed to create build',
          progress: 0,
        });
      });
      onError?.(error instanceof Error ? error : new Error('Failed to create build'));
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 overflow-y-auto px-4 py-2">
        {currentStep === 'details' ? (
          <BuildForm
            buildDetails={buildDetails}
            onChange={setBuildDetails}
            onSubmit={handleFormSubmit}
            submitLabel="Next"
            isSubmitting={false}
          />
        ) : (
          <>
            {isAllComplete ? (
              <div className="flex flex-col items-center justify-center py-6 space-y-3">
                <div className="w-12 h-12 bg-green-50 rounded-full flex items-center justify-center">
                  <Check className="w-6 h-6 text-green-500" />
                </div>
                <p className="text-sm text-gray-600">Successfully uploaded {fileStatuses.length} files</p>
              </div>
            ) : (
              <FileUploader
                fileStatuses={fileStatuses}
                onFilesSelected={addFiles}
                onFileRemove={removeFile}
                isUploading={fileStatuses.some(s => s.status === 'uploading')}
                onUploadClick={uploadAllFiles}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default StreamlinedBuildUploader;