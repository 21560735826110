import { createContext } from "react";
import { SlideOverType } from "../SlideOverType";


// Context to manage panel visibility
interface SlideOverContextType {
    activePanel: SlideOverType;
    setActivePanel: (panel: SlideOverType) => void;
}

export const SlideOverContext = createContext<SlideOverContextType>({
    activePanel: SlideOverType.NONE,
    setActivePanel: () => { },
});
