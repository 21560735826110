import { useState, useEffect } from 'react';
import { ProjectWithFavorite } from "../types";

const FAVORITES_KEY = 'project_favorites';

export const useFavorites = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);

  const loadFavorites = (): string[] => {
    try {
      const saved = localStorage.getItem(FAVORITES_KEY);
      const loadedFavorites = saved ? JSON.parse(saved) : [];
      setFavorites(loadedFavorites);
      return loadedFavorites;
    } catch (error) {
      console.error('Error loading favorites:', error);
      return [];
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      loadFavorites();
      setIsInitialized(true);
    }
  }, [isInitialized]);

  const saveFavorites = (newFavorites: string[]) => {
    try {
      localStorage.setItem(FAVORITES_KEY, JSON.stringify(newFavorites));
      setFavorites(newFavorites);
    } catch (error) {
      console.error('Error saving favorites:', error);
    }
  };

  const toggleFavorite = (
    projectId: string,
    projects: ProjectWithFavorite[],
    setProjects: (projects: ProjectWithFavorite[]) => void
  ) => {
    const newFavorites = favorites.includes(projectId)
      ? favorites.filter((id) => id !== projectId)
      : [...favorites, projectId];

    saveFavorites(newFavorites);

    setProjects(
      projects
        .map((project) => ({
          ...project,
          isFavorite: newFavorites.includes(project.projectId),
        }))
        .sort((a, b) => {
          if (a.isFavorite === b.isFavorite) return a.projectSlug.localeCompare(b.projectSlug);
          return a.isFavorite ? -1 : 1;
        })
    );
  };

  return {
    favorites,
    loadFavorites,
    toggleFavorite
  };
};
