export type BuildStatus = 'SUCCESS' | 'FAILURE' | 'PENDING' | 'IN_PROGRESS' | 'CANCELED' | 'OTHER';

export const buildStatusOptions: BuildStatus[] = [
  'SUCCESS',
  'FAILURE',
  'PENDING',
  'IN_PROGRESS',
  'CANCELED',
  'OTHER',
];

export interface FileUploadStatus {
  file: File;
  progress: number;
  status: 'idle' | 'uploading' | 'success' | 'error';
  errorMessage?: string;
}
