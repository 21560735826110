import React, { useState } from 'react';
import { Mail, User, Lock, AlertCircle } from 'lucide-react';

interface AccountStepProps {
  formData: {
    email: string;
    displayName: string;
    password: string;
  };
  onChange: (field: string, value: string) => void;
}

interface InputWithIconProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: React.ReactNode;
  error?: string;
}

interface ValidationErrors {
  email?: string;
  displayName?: string;
  password?: string;
}

const InputWithIcon: React.FC<InputWithIconProps> = ({ icon, error, className, ...props }) => (
  <div className="space-y-1">
    <div className="relative">
      <input
        {...props}
        className={`w-full rounded-lg border-2 py-3 pl-12 pr-4 text-gray-700 transition-all duration-150
          ${
            error
              ? 'border-red-300 focus:border-red-400 focus:ring-2 focus:ring-red-300'
              : 'border-gray-300 focus:border-green-400 focus:ring-2 focus:ring-green-300'
          } ${className}`}
      />
      <div className="absolute left-4 top-3.5 text-gray-500">{icon}</div>
    </div>
    {error && (
      <div className="flex items-center gap-1 text-sm text-red-600">
        <AlertCircle className="h-4 w-4" />
        <span>{error}</span>
      </div>
    )}
  </div>
);

const AccountStep: React.FC<AccountStepProps> = ({ formData, onChange }) => {
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [errors, setErrors] = useState<ValidationErrors>({});

  // Validation rules
  const validateField = (field: string, value: string): string => {
    switch (field) {
      case 'email':
        if (!value) return 'Email is required';
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'Please enter a valid email address';
        }
        if (!value.includes('.')) return 'Please enter a complete email address';
        break;

      case 'displayName':
        if (!value) return 'Full name is required';
        if (value.length < 2) return 'Name must be at least 2 characters';
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          return 'Name can only contain letters and spaces';
        }
        if (!value.includes(' ')) return 'Please enter your full name';
        break;

      case 'password':
        if (!value) return 'Password is required';
        if (value.length < 8) {
          return 'Password must be at least 8 characters';
        }
        if (!/[A-Z]/.test(value)) {
          return 'Password must contain at least one uppercase letter';
        }
        if (!/[a-z]/.test(value)) {
          return 'Password must contain at least one lowercase letter';
        }
        if (!/[0-9]/.test(value)) {
          return 'Password must contain at least one number';
        }
        if (!/[!@#$%^&*]/.test(value)) {
          return 'Password must contain at least one special character';
        }
        break;
    }
    return '';
  };

  // Handle blur events
  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const error = validateField(field, formData[field as keyof typeof formData]);
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  // Handle change events
  const handleChange = (field: string, value: string) => {
    onChange(field, value);
    if (touched[field]) {
      const error = validateField(field, value);
      setErrors((prev) => ({ ...prev, [field]: error }));
    }
  };

  // Check if form is valid
  /*const isFormValid = () => {
    const allErrors = {
      email: validateField('email', formData.email),
      displayName: validateField('displayName', formData.displayName),
      password: validateField('password', formData.password)
    };
    return !Object.values(allErrors).some(error => error);
  };*/

  return (
    <div className="space-y-6">
      <div className="mb-8 text-center">
        <h2 className="text-3xl font-bold text-navy-900">Create your account</h2>
        <p className="mt-2 text-xl text-gray-600">Start managing your builds efficiently</p>
      </div>

      <div className="space-y-4">
        <InputWithIcon
          type="email"
          name="email"
          value={formData.email}
          onChange={(e) => handleChange('email', e.target.value)}
          onBlur={() => handleBlur('email')}
          placeholder="Work email"
          icon={<Mail className="h-5 w-5" />}
          error={touched.email ? errors.email : undefined}
        />

        <InputWithIcon
          type="text"
          name="displayName"
          value={formData.displayName}
          onChange={(e) => handleChange('displayName', e.target.value)}
          onBlur={() => handleBlur('displayName')}
          placeholder="Full name"
          icon={<User className="h-5 w-5" />}
          error={touched.displayName ? errors.displayName : undefined}
        />

        <InputWithIcon
          type="password"
          name="password"
          value={formData.password}
          onChange={(e) => handleChange('password', e.target.value)}
          onBlur={() => handleBlur('password')}
          placeholder="Password"
          icon={<Lock className="h-5 w-5" />}
          error={touched.password ? errors.password : undefined}
        />
      </div>
    </div>
  );
};

export default AccountStep;
