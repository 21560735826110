import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { BuildDetails } from '@/models/buildDetails';
import SearchableSelect from '@/ui/SearchableSelect/SearchableSelect';
import { PLATFORM_TYPES } from '@/models/platformType';
import { BUILD_PLATFORMS } from '@/models/buildPlatform';
import { BUILD_TYPES } from '@/models/buildType';
import { buildStatusOptions } from './types';

interface BuildFormWizardProps {
    buildDetails: BuildDetails;
    onSubmit: (e: React.FormEvent) => void;
    onChange: (buildDetails: BuildDetails) => void;
    submitLabel?: string;
    isSubmitting?: boolean;
}

const BuildFormWizard: React.FC<BuildFormWizardProps> = ({
    buildDetails,
    onSubmit,
    onChange,
    submitLabel = 'Save',
    isSubmitting = false,
}) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = e.target;
        onChange({
            ...buildDetails,
            [name]: value,
        });
    };

    const handleSelectChange = (name: string, value: string) => {
        onChange({
            ...buildDetails,
            [name]: value,
        });
    };

    const handleTagInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const target = e.target as HTMLInputElement;
            const newTag = target.value.trim();
            if (newTag && !buildDetails.tags.includes(newTag)) {
                onChange({
                    ...buildDetails,
                    tags: [...buildDetails.tags, newTag],
                });
                target.value = '';
            }
        }
    };

    const removeTag = (tagToRemove: string) => {
        onChange({
            ...buildDetails,
            tags: buildDetails.tags.filter((tag) => tag !== tagToRemove),
        });
    };

    const isBasicDetailsValid = () => {
        return (
            buildDetails.version.trim() !== '' &&
            buildDetails.buildName.trim() !== '' &&
            buildDetails.platform !== null &&
            buildDetails.buildType !== null
        );
    };

    const wizardSteps = [
        {
            title: 'Basic Details',
            content: (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Version</label>
                        <Input
                            type="text"
                            name="version"
                            required
                            value={buildDetails.version}
                            onChange={handleChange}
                            placeholder="1.0.0"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Build Name</label>
                        <Input
                            type="text"
                            name="buildName"
                            required
                            value={buildDetails.buildName}
                            onChange={handleChange}
                            placeholder="MyApp Build"
                        />
                    </div>

                    <div className="space-y-2">
                        <SearchableSelect
                            options={PLATFORM_TYPES}
                            value={buildDetails.platform}
                            onValueChange={(value) => handleSelectChange('platform', value)}
                            label="Platform Type"
                            placeholder="Select platform type"
                        />
                    </div>

                    <div className="space-y-2">
                        <SearchableSelect
                            options={BUILD_TYPES}
                            value={buildDetails.buildType}
                            onValueChange={(value) => handleSelectChange('buildType', value)}
                            label="Build Type"
                            formatLabel={(value) => value}
                        />
                    </div>
                </div>
            ),
            isValid: isBasicDetailsValid,
        },
        {
            title: 'Platform Details',
            content: (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="space-y-2">
                        <SearchableSelect
                            options={BUILD_PLATFORMS}
                            value={buildDetails.buildPlatform}
                            onValueChange={(value) => handleSelectChange('buildPlatform', value)}
                            label="Build Platform"
                            placeholder="Select build platform"
                        />
                    </div>

                    <div className="space-y-2">
                        <SearchableSelect
                            options={buildStatusOptions}
                            value={buildDetails.buildStatus}
                            onValueChange={(value) => handleSelectChange('buildStatus', value)}
                            label="Build Status"
                            placeholder="Select build status"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Platform Architecture</label>
                        <Input
                            type="text"
                            name="platformArchitecture"
                            value={buildDetails.platformArchitecture}
                            onChange={handleChange}
                            placeholder="arm64, x86_64"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Target Platforms</label>
                        <Input
                            type="text"
                            name="targetPlatforms"
                            value={buildDetails.targetPlatforms}
                            onChange={handleChange}
                            placeholder="ANDROID, IOS"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: 'Source Control',
            content: (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Commit Hash</label>
                        <Input
                            type="text"
                            name="commitHash"
                            value={buildDetails.commitHash}
                            onChange={handleChange}
                            placeholder="git commit hash"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Branch Name</label>
                        <Input
                            type="text"
                            name="branchName"
                            value={buildDetails.branchName}
                            onChange={handleChange}
                            placeholder="main"
                        />
                    </div>

                    <div className="col-span-2 space-y-2">
                        <label className="text-sm font-medium text-gray-700">Description</label>
                        <Textarea
                            name="description"
                            value={buildDetails.description}
                            onChange={handleChange}
                            rows={3}
                            placeholder="Build description"
                            className="resize-none"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: 'Release Notes',
            content: (
                <div className="space-y-4">
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Release Notes</label>
                        <Textarea
                            name="releaseNotes"
                            value={buildDetails.releaseNotes}
                            onChange={handleChange}
                            rows={8}
                            placeholder="What's new in this build?"
                            className="resize-none"
                        />
                    </div>
                </div>
            ),
        },
        {
            title: 'Tags',
            content: (
                <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Tags</label>
                    <div className="flex flex-wrap gap-2 p-2 rounded-lg border border-gray-300">
                        {buildDetails.tags.map((tag) => (
                            <span
                                key={tag}
                                className="flex items-center gap-1 px-3 py-1 text-sm bg-green-100 text-green-600 rounded-full"
                            >
                                {tag}
                                <button
                                    type="button"
                                    onClick={() => removeTag(tag)}
                                    className="text-green-600 hover:text-green-700"
                                >
                                    <X className="w-4 h-4" />
                                </button>
                            </span>
                        ))}
                        <Input
                            type="text"
                            onKeyDown={handleTagInput}
                            className="flex-grow border-0 bg-transparent px-2 py-1"
                            placeholder="Add tags (press Enter)"
                        />
                    </div>
                </div>
            ),
        },
    ];

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (currentStep === wizardSteps.length - 1) {
            onSubmit(e);
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex justify-between mb-4">
                {wizardSteps.map((step, index) => (
                    <button
                        key={step.title}
                        type="button"
                        onClick={() => setCurrentStep(index)}
                        className={`px-4 py-2 text-sm font-medium rounded-full ${currentStep === index
                                ? 'bg-green-500 text-white'
                                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                            }`}
                    >
                        {step.title}
                    </button>
                ))}
            </div>

            <Card>
                <CardContent className="pt-6">
                    {wizardSteps[currentStep].content}
                </CardContent>
            </Card>

            <div className="flex justify-between">
                <Button
                    type="button"
                    onClick={() => setCurrentStep(currentStep - 1)}
                    disabled={currentStep === 0}
                    variant="outline"
                >
                    Previous
                </Button>
                <Button
                    type="submit"
                    disabled={
                        (currentStep === 0 && !isBasicDetailsValid()) ||
                        (currentStep === wizardSteps.length - 1 && isSubmitting)
                    }
                    className="bg-green-400 text-white hover:bg-green-500"
                >
                    {currentStep === wizardSteps.length - 1 ? submitLabel : 'Next'}
                </Button>
            </div>
        </form>
    );
};

export default BuildFormWizard;