import React, { useState, useEffect, useMemo } from 'react';
import { Search, Users, Plus, X, CheckCircle, Clock, XCircle } from 'lucide-react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { useParams } from 'react-router-dom';

interface WorkspaceMember {
  userId: string;
  email: string;
  displayName?: string;
  role: 'OWNER' | 'ADMIN' | 'DEVELOPER' | 'VIEWER';
  joinedDate: string;
  status: 'PENDING' | 'ACCEPTED' | 'DECLINED';
}

interface InviteMemberRequest {
  email: string;
  role: WorkspaceMember['role'];
}

interface UpdateRoleRequest {
  workspaceRole: WorkspaceMember['role'];
}

const WorkspaceMembership = () => {
  const { workspaceSlug } = useParams();
  const [members, setMembers] = useState<WorkspaceMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterRole, setFilterRole] = useState<string>('ALL');
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState<WorkspaceMember['role']>('DEVELOPER');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteStatus, setInviteStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadError, setLoadError] = useState<string | null>(null);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<WorkspaceMember | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdatingRole, setIsUpdatingRole] = useState<string | null>(null);

  const loadMembers = async () => {
    setIsLoading(true);
    setLoadError(null);

    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/members`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to load workspace members');
      }

      const data = await response.json();
      setMembers(data);
    } catch (error) {
      setLoadError(error instanceof Error ? error.message : 'Failed to load members');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMembers();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);





  const handleRoleChange = async (userId: string, newRole: WorkspaceMember['role']) => {
    setIsUpdatingRole(userId);
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/member/${userId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
          body: JSON.stringify({
            workspaceRole: newRole,
          } as UpdateRoleRequest),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update role');
      }

      setMembers(
        members.map((member) => (member.userId === userId ? { ...member, role: newRole } : member)),
      );
    } catch (error) {
      console.error('Failed to update role:', error);
      alert(error instanceof Error ? error.message : 'Failed to update role');
    } finally {
      setIsUpdatingRole(null);
    }
  };

  const handleInviteMember = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setInviteStatus('idle');
    setErrorMessage('');

    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/invite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
          body: JSON.stringify({
            email: inviteEmail,
            role: inviteRole,
          } as InviteMemberRequest),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send invitation');
      }

      setInviteStatus('success');
      await loadMembers();

      setTimeout(() => {
        setIsInviteModalOpen(false);
        setInviteEmail('');
        setInviteRole('DEVELOPER');
        setInviteStatus('idle');
      }, 2000);
    } catch (error) {
      setInviteStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'Failed to send invitation');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteMember = async () => {
    if (!memberToDelete) return;

    setIsDeleting(true);
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/member/${memberToDelete.userId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
          },
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to remove member');
      }

      setMembers(members.filter((m) => m.userId !== memberToDelete.userId));
      setIsDeleteModalOpen(false);
      setMemberToDelete(null);
    } catch (error) {
      console.error('Failed to delete member:', error);
      alert(error instanceof Error ? error.message : 'Failed to remove member');
    } finally {
      setIsDeleting(false);
    }
  };

  const filteredMembers = useMemo(() => {
    return members.filter((member) => {
      const searchFields = [
        member.email.toLowerCase(),
        member.displayName?.toLowerCase() || '',
        member.role.toLowerCase(),
        member.status.toLowerCase(),
      ];

      const searchTerms = debouncedSearchQuery.toLowerCase().split(' ');
      const matchesSearch = searchTerms.every((term) =>
        searchFields.some((field) => field.includes(term)),
      );

      const matchesRole = filterRole === 'ALL' || member.role === filterRole;

      return matchesSearch && matchesRole;
    });
  }, [members, debouncedSearchQuery, filterRole]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setFilterRole('ALL');
  };

  const InviteMemberModal = () =>
    isInviteModalOpen ? (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg w-full max-w-md relative">
          <button
            onClick={() => setIsInviteModalOpen(false)}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>

          <div className="p-6">
            <div className="mb-6">
              <h2 className="text-xl font-bold text-gray-900">Invite Team Member</h2>
              <p className="mt-1 text-sm text-gray-500">
                Send an invitation to join your workspace. They'll receive an email with
                instructions.
              </p>
            </div>

            <form onSubmit={handleInviteMember} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
                  placeholder="colleague@company.com"
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div>
                <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">
                  Role
                </label>
                <select
                  id="role"
                  value={inviteRole}
                  onChange={(e) => setInviteRole(e.target.value as WorkspaceMember['role'])}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
                  disabled={isSubmitting}
                >
                  <option value="ADMIN">Admin</option>
                  <option value="DEVELOPER">Developer</option>
                  <option value="VIEWER">Viewer</option>
                </select>
              </div>

              {inviteStatus === 'success' && (
                <div className="p-3 bg-green-50 border border-green-200 rounded-lg">
                  <p className="text-sm text-green-800">Invitation sent successfully!</p>
                </div>
              )}

              {inviteStatus === 'error' && (
                <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
                  <p className="text-sm text-red-800">{errorMessage}</p>
                </div>
              )}

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => setIsInviteModalOpen(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !inviteEmail}
                  className="px-4 py-2 bg-green-400 text-white rounded-lg hover:bg-green-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center"
                >
                  {isSubmitting ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Sending Invitation...
                    </>
                  ) : (
                    'Send Invitation'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : null;

  const DeleteMemberModal = () =>
    isDeleteModalOpen && memberToDelete ? (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg w-full max-w-md p-6">
          <h3 className="text-xl font-bold text-gray-900 mb-4">Remove Team Member</h3>
          <p className="text-gray-600 mb-6">
            Are you sure you want to remove{' '}
            <span className="font-medium">{memberToDelete.email}</span> from the workspace? This
            action cannot be undone.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => {
                setIsDeleteModalOpen(false);
                setMemberToDelete(null);
              }}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              disabled={isDeleting}
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteMember}
              disabled={isDeleting}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center"
            >
              {isDeleting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Removing...
                </>
              ) : (
                'Remove Member'
              )}
            </button>
          </div>
        </div>
      </div>
    ) : null;

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-center">
          <svg
            className="animate-spin h-8 w-8 text-green-400 mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="mt-2 text-gray-600">Loading workspace members...</p>
        </div>
      </div>
    );
  }

  if (loadError) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-center">
          <XCircle className="h-8 w-8 text-red-500 mx-auto" />
          <p className="mt-2 text-gray-600">{loadError}</p>
          <button
            onClick={loadMembers}
            className="mt-4 px-4 py-2 bg-green-400 text-white rounded-lg hover:bg-green-500 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="bg-white border-b border-gray-300">
        <div className="max-w-7xl mx-auto px-6 py-8">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h1 className="text-4xl font-bold text-navy-900">Workspace Members</h1>
              <p className="mt-2 text-gray-600">
                Manage your workspace team and their access levels
              </p>
            </div>
            <button
              className="inline-flex items-center px-6 py-2 bg-green-400 text-white rounded-lg hover:bg-green-500 transition-colors"
              onClick={() => setIsInviteModalOpen(true)}
            >
              <Plus className="w-5 h-5 mr-2" />
              Invite Members
            </button>
          </div>

          <div className="grid grid-cols-4 gap-6">
            <div className="p-6 bg-white rounded-xl border border-gray-300">
              <div className="w-12 h-12 bg-green-50 rounded-lg flex items-center justify-center mb-4">
                <Users className="w-6 h-6 text-green-400" />
              </div>
              <p className="text-gray-600">Total Members</p>
              <p className="text-2xl font-bold mt-1">{members.length}</p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-300">
              <div className="w-12 h-12 bg-green-50 rounded-lg flex items-center justify-center mb-4">
                <Clock className="w-6 h-6 text-green-400" />
              </div>
              <p className="text-gray-600">Pending Invites</p>
              <p className="text-2xl font-bold mt-1">
                {members.filter((m) => m.status === 'PENDING').length}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <input
                type="text"
                placeholder="Search by name, email, role, or status..."
                value={searchQuery}
                onChange={handleSearchInput}
                className="w-64 pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="w-4 h-4" />
                </button>
              )}
            </div>
            <select
              value={filterRole}
              onChange={(e) => setFilterRole(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              <option value="ALL">All Roles</option>
              <option value="OWNER">Owner</option>
              <option value="ADMIN">Admin</option>
              <option value="DEVELOPER">Developer</option>
              <option value="VIEWER">Viewer</option>
            </select>
            {(searchQuery || filterRole !== 'ALL') && (
              <button
                onClick={handleResetFilters}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                Clear filters
              </button>
            )}
          </div>
          <div className="text-sm text-gray-600">
            {filteredMembers.length} {filteredMembers.length === 1 ? 'member' : 'members'} found
          </div>
        </div>

        {filteredMembers.length === 0 && !isLoading && (
          <div className="text-center py-8">
            <Users className="w-12 h-12 text-gray-400 mx-auto mb-3" />
            <h3 className="text-lg font-medium text-gray-900 mb-1">No members found</h3>
            <p className="text-gray-600">
              Try adjusting your search or filter to find what you're looking for
            </p>
          </div>
        )}

        <div className="bg-white rounded-xl border border-gray-300 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-300">
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Member</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Role</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Status</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Joined</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {filteredMembers.map((member) => (
                <tr key={member.userId} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-4">
                      <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                        <span className="text-sm font-medium">
                          {member.displayName?.charAt(0) || member.email.charAt(0).toUpperCase()}
                        </span>
                      </div>
                      <div>
                        <p className="font-medium text-navy-900">
                          {member.displayName || member.email}
                        </p>
                        <p className="text-sm text-gray-500">{member.email}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="px-3 py-1 text-sm font-medium text-green-600 bg-green-100 rounded-full">
                      {member.role}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    {member.status === 'ACCEPTED' ? (
                      <span className="inline-flex items-center text-sm text-green-600">
                        <CheckCircle className="w-4 h-4 mr-1" />
                        Active
                      </span>
                    ) : member.status === 'PENDING' ? (
                      <span className="inline-flex items-center text-sm text-yellow-600">
                        <Clock className="w-4 h-4 mr-1" />
                        Pending
                      </span>
                    ) : (
                      <span className="inline-flex items-center text-sm text-red-600">
                        <XCircle className="w-4 h-4 mr-1" />
                        Declined
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600">
                    {new Date(member.joinedDate).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center space-x-3">
                      {member.role !== 'OWNER' && (
                        <>
                          <select
                            value={member.role}
                            onChange={(e) =>
                              handleRoleChange(
                                member.userId,
                                e.target.value as WorkspaceMember['role'],
                              )
                            }
                            className="px-3 py-1.5 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
                            disabled={isUpdatingRole === member.userId}
                          >
                            <option value="ADMIN">Admin</option>
                            <option value="DEVELOPER">Developer</option>
                            <option value="VIEWER">Viewer</option>
                          </select>
                          <button
                            onClick={() => {
                              setMemberToDelete(member);
                              setIsDeleteModalOpen(true);
                            }}
                            className="text-gray-400 hover:text-gray-600"
                          >
                            <X className="w-5 h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <InviteMemberModal />
      <DeleteMemberModal />
    </div>
  );
};

export default WorkspaceMembership;
