import { BuildStatus } from '@/features/builds/types';
import { BuildPlatform } from '@/models/buildPlatform';
import { PlatformType } from '@/models/platformType';
import { BuildType } from '@/models/buildType';

export interface BuildDetails {
  buildId?: string;
  version: string;
  buildName: string;
  platform: PlatformType;
  platformArchitecture: string;
  buildType: BuildType;
  commitHash: string;
  branchName: string;
  description: string;
  buildSize: number;
  buildStatus: BuildStatus;
  releaseNotes: string;
  targetPlatforms: string;
  ciUrl: string;
  buildDuration: number;
  tags: string[];
  hostname: string;
  uploaderName: string;
  uploaderSdkVersion: string;
  buildPlatform: BuildPlatform;
  applicationOwner: string;
  applicationName: string;
  applicationTeam: string;
  createdAt?: string;
  updatedAt?: string;
}
export const defaultBuildDetails: BuildDetails = {
  version: '',
  buildName: '',
  platform: 'OTHER',
  platformArchitecture: '',
  buildType: 'CI',
  commitHash: '',
  branchName: '',
  description: '',
  buildSize: 0,
  buildStatus: 'SUCCESS',
  releaseNotes: '',
  targetPlatforms: '',
  ciUrl: '',
  buildDuration: 0,
  tags: [],
  hostname: window.location.hostname,
  uploaderName: '',
  uploaderSdkVersion: '1.0.0',
  buildPlatform: 'OTHER',
  applicationOwner: '',
  applicationName: '',
  applicationTeam: '',
};
