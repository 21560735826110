import React, { useState } from 'react';
import { X } from 'lucide-react';
import { SlideOverType } from './SlideOverType';
import { useSlideOver } from './hooks/useSlideOver';
import { SlideOverContext } from './context/slideOverContext';

// Provider component
export const SlideOverProvider = ({ children }: { children: React.ReactNode }) => {
    const [activePanel, setActivePanel] = useState<SlideOverType>(SlideOverType.NONE);

    const handlePanelChange = (panel: SlideOverType) => {
        // If the same panel is clicked twice, close it by setting to NONE
        if (panel === activePanel) {
            setActivePanel(SlideOverType.NONE);
        } else {
            setActivePanel(panel);
        }
    };

    return (
        <SlideOverContext.Provider value={{ activePanel, setActivePanel: handlePanelChange }}>
            {children}
        </SlideOverContext.Provider>
    );
};

interface SlideOverProps {
    type: SlideOverType;
    title: string;
    children: React.ReactNode;
    width?: string;
}

export const SlideOver = ({
    type,
    title,
    children,
    width = 'w-96'
}: SlideOverProps) => {
    const { activePanel, setActivePanel } = useSlideOver();
    const isOpen = activePanel === type;
    const [isVisible, setIsVisible] = useState(false);

    // Use React's useEffect to handle the visibility state
    React.useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
        } else {
            // When closing, wait for the transition to complete before hiding content
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 300); // This should match the duration in the transition class
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    return (
        <div
            className={`fixed inset-y-0 right-0 ${width} bg-white border-l border-gray-300 transform transition-transform duration-300 ease-in-out z-[49] ${isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
        >
            {(isVisible || isOpen) && (
                <div className="h-full flex flex-col relative w-full">
                    <div className="p-6 border-b border-gray-300 flex justify-between items-center">
                        <h2 className="text-xl font-bold text-navy-900">{title}</h2>
                        <button
                            onClick={() => setActivePanel(SlideOverType.NONE)}
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                            <X size={20} className="text-gray-600" />
                        </button>
                    </div>

                    <div className="flex-1 p-6 overflow-y-auto">
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SlideOver;