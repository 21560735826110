import React, { useState } from 'react';
import { FolderGit2, ExternalLink, PlayCircle, Star, Edit2, Trash2, Copy, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { ProjectWithFavorite } from './types';

interface ProjectCardProps {
    project: ProjectWithFavorite;
    workspaceSlug: string;
    onToggleFavorite: (projectId: string) => void;
    onEdit: (project: ProjectWithFavorite) => void;
    onDelete: (project: ProjectWithFavorite) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
    project,
    workspaceSlug,
    onToggleFavorite,
    onEdit,
    onDelete,
}) => {
    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();

    const handleCopySlug = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            await navigator.clipboard.writeText(project.projectSlug);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy slug:', err);
        }
    };

    const handleCardClick = () => {
        navigate(`/workspace/${workspaceSlug}/projects/${project.projectSlug}/builds`);
    };

    return (
        <Card
            onClick={handleCardClick}
            className="hover:shadow-md transition-shadow duration-200 cursor-pointer group"
        >
            <CardContent className="p-6">
                <div className="flex gap-6">
                    {project.imageUrl ? (
                        <img
                            src={project.imageUrl}
                            alt={`${project.name} project thumbnail`}
                            className="w-16 h-16 rounded-lg object-cover flex-shrink-0"
                        />
                    ) : (
                        <div className="w-16 h-16 rounded-lg bg-green-50 flex items-center justify-center flex-shrink-0">
                            <FolderGit2 className="w-8 h-8 text-green-400" />
                        </div>
                    )}

                    <div className="flex-grow min-w-0">
                        <div className="flex items-center justify-between gap-4 mb-3">
                            <div className="flex items-center gap-3 min-w-0">
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onToggleFavorite(project.projectId);
                                                }}
                                                className="p-1 hover:bg-gray-100 flex-shrink-0"
                                            >
                                                <Star
                                                    className={`w-5 h-5 transition-colors ${project.isFavorite
                                                            ? 'fill-yellow-400 text-yellow-400'
                                                            : 'text-gray-300 hover:text-gray-400'
                                                        }`}
                                                />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {project.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>

                                <div className="min-w-0">
                                    <h3 className="text-xl font-bold text-gray-900 truncate mb-1">
                                        {project.name}
                                    </h3>
                                    <div className="flex items-center gap-2">
                                        <Badge variant="secondary" className="text-xs font-mono">
                                            {project.projectSlug}
                                        </Badge>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        onClick={handleCopySlug}
                                                        className="p-1 h-6 text-gray-400 hover:text-gray-600"
                                                    >
                                                        {copied ? (
                                                            <Check className="w-3 h-3 text-green-500" />
                                                        ) : (
                                                            <Copy className="w-3 h-3" />
                                                        )}
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    {copied ? 'Copied!' : 'Copy slug'}
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center gap-2 flex-shrink-0">
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onEdit(project);
                                                }}
                                                className="p-2 text-gray-400 hover:text-gray-600"
                                            >
                                                <Edit2 className="w-4 h-4" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Edit project</TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>

                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDelete(project);
                                                }}
                                                className="p-2 text-gray-400 hover:text-red-600"
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>Delete project</TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>
                        </div>

                        <p className="text-gray-600 text-base mb-4 text-left line-clamp-2">
                            {project.description || 'No description provided'}
                        </p>

                        <div className="flex items-center gap-6">
                            <div className="inline-flex items-center text-gray-600 group-hover:text-green-600 transition-colors gap-2 text-sm font-medium">
                                <PlayCircle className="w-4 h-4" />
                                View Builds
                            </div>

                            <a
                                href={project.repository}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors gap-2 text-sm font-medium"
                            >
                                <ExternalLink className="w-4 h-4" />
                                Repository
                            </a>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default ProjectCard;