import { ProgressStepsProps, StepInfo } from '@/models/onboarding';
import { Github, HelpCircle, Linkedin, Twitter } from 'lucide-react';

const Header: React.FC = () => (
  <header className="fixed inset-x-0 top-0 z-50 border-b border-gray-300 bg-white/80 backdrop-blur">
    <div className="mx-auto max-w-7xl px-6">
      <div className="flex h-16 items-center justify-between">
        <div className="flex items-center">
          <img src="/api/placeholder/32/32" alt="Logo" className="h-8 w-8" />
          <span className="ml-2 text-xl font-semibold text-navy-900">Releases.</span>
        </div>
        <div className="flex items-center space-x-4">
          <a href="#" className="text-gray-500 transition-colors duration-150 hover:text-gray-900">
            <HelpCircle className="h-5 w-5" />
          </a>
        </div>
      </div>
    </div>
  </header>
);
const Footer: React.FC = () => (
  <footer className="fixed inset-x-0 bottom-0 border-t border-gray-300 bg-white">
    <div className="mx-auto max-w-7xl px-6">
      <div className="flex h-16 items-center justify-between">
        <div className="text-sm text-gray-500">© 2024 Releases. All rights reserved.</div>
        <div className="flex space-x-6">
          {[
            { Icon: Github, href: '#' },
            { Icon: Twitter, href: '#' },
            { Icon: Linkedin, href: '#' },
          ].map(({ Icon, href }) => (
            <a
              key={Icon.name}
              href={href}
              className="text-gray-500 transition-colors duration-150 hover:text-gray-700"
            >
              <Icon className="h-5 w-5" />
            </a>
          ))}
        </div>
      </div>
    </div>
  </footer>
);

const ProgressSteps: React.FC<ProgressStepsProps> = ({ currentStep }) => {
  const steps: StepInfo[] = [{ number: 1, title: 'Account' }];

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between">
        {steps.map((stepInfo, index) => (
          <div key={stepInfo.number} className="flex flex-1 items-center">
            <div className="flex flex-1 flex-col items-center">
              <div
                className={`
                    flex h-10 w-10 items-center justify-center rounded-full text-lg font-semibold text-white
                    ${
                      currentStep > stepInfo.number
                        ? 'bg-green-400'
                        : currentStep === stepInfo.number
                          ? 'bg-green-400'
                          : 'bg-gray-200'
                    }
                    transition-colors duration-150
                  `}
              >
                {currentStep > stepInfo.number ? '✓' : stepInfo.number}
              </div>
              <span className="mt-2 text-sm font-medium text-gray-600">{stepInfo.title}</span>
              {index < steps.length - 1 && (
                <div
                  className={`mt-4 h-1 w-full ${
                    currentStep > stepInfo.number ? 'bg-green-400' : 'bg-gray-200'
                  } transition-colors duration-150`}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Header, Footer, ProgressSteps };
