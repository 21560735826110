// hooks/useBuildFiles.ts
import { useState, useEffect } from 'react';
import { EndpointConfig, TokenService } from '@/lib/token';
import { BuildFile } from '../BuildFileList';


interface UseBuildFilesProps {
  workspaceSlug: string;
  projectSlug: string;
  buildId: string;
}

interface UseBuildFilesReturn {
  files: BuildFile[];
  isLoading: boolean;
  error: string | null;
  refetch: () => Promise<void>;
}

export const useBuildFiles = ({
  workspaceSlug,
  projectSlug,
  buildId,
}: UseBuildFilesProps): UseBuildFilesReturn => {
  const [files, setFiles] = useState<BuildFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBuildFiles = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await fetch(
        `${EndpointConfig.baseUrl}/api/v1/workspace/${workspaceSlug}/project/${projectSlug}/build/${buildId}/files`,
        {
          headers: {
            Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to fetch build files');
      }

      const data = await response.json();
      setFiles(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch build files');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (buildId) {
      fetchBuildFiles();
    }
  }, [workspaceSlug, projectSlug, buildId]);

  return {
    files,
    isLoading,
    error,
    refetch: fetchBuildFiles,
  };
};