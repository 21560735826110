import { useState } from 'react';
import { EndpointConfig } from '../../../lib/token';
import { BackendError } from '@/models/errorTypes';
import { createBackendError, createGenericError } from '@/ui/Errors/errorFunctions';

export interface UseRegisterReturn {
  createAccount: (email: string, password: string, displayName: string) => Promise<RegisterResult>;
  isLoading: boolean;
  error?: BackendError | null;
}

export interface RegisterResult {
  success: boolean;
  error?: BackendError;
  userId?: string;
}

export const useRegister = (): UseRegisterReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<BackendError | null>();

  const createAccount = async (
    email: string,
    password: string,
    displayName: string,
  ): Promise<RegisterResult> => {
    setIsLoading(true);
    setError(null);

    try {
      const registerResponse = await fetch(`${EndpointConfig.baseUrl}/api/v1/onboarding/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          fullName: displayName,
        }),
      });

      const registerData = await registerResponse.json();
      console.log(registerResponse);
      if (!registerResponse.ok) {
        const backendError: BackendError = createBackendError(registerData, registerResponse);
        setError(backendError);
        return {
          success: false,
          error: backendError,
        };
      }
      return {
        success: true,
        userId: registerData.userId,
      };
    } catch (error) {
      console.log('Error creating account:', error);
      return {
        success: false,
        error: createGenericError(),
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createAccount,
    isLoading,
    error,
  };
};
