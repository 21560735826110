export type BuildType =
  | 'PRE_ALPHA'
  | 'ALPHA'
  | 'BETA'
  | 'RELEASE_CANDIDATE'
  | 'RELEASE'
  // Environment Types
  | 'DEVELOPMENT'
  | 'STAGING'
  | 'PRODUCTION'
  // Special Builds
  | 'DEBUG'
  | 'HOTFIX'
  | 'PATCH'
  // Testing & Integration
  | 'CI'
  | 'NIGHTLY'
  | 'SNAPSHOT'
  | 'CANARY'
  | 'QA'
  // Preview Versions
  | 'PREVIEW'
  | 'EARLY_ACCESS'
  | 'TECH_PREVIEW'
  // Internal Builds
  | 'INTERNAL'
  | 'DEMO'
  | 'PROTOTYPE'
  // Other
  | 'CUSTOM';

export const BUILD_TYPES: BuildType[] = [
  'PRE_ALPHA',
  'ALPHA',
  'BETA',
  'RELEASE_CANDIDATE',
  'RELEASE',
  'DEVELOPMENT',
  'STAGING',
  'PRODUCTION',
  'DEBUG',
  'HOTFIX',
  'PATCH',
  'CI',
  'NIGHTLY',
  'SNAPSHOT',
  'CANARY',
  'QA',
  'PREVIEW',
  'EARLY_ACCESS',
  'TECH_PREVIEW',
  'INTERNAL',
  'DEMO',
  'PROTOTYPE',
  'CUSTOM',
];
