import { useState, useEffect } from 'react';
import { Terminal, Upload, Key, Command, AlertCircle, Menu, X } from 'lucide-react';

const sections = [
  {
    title: 'Getting Started',
    subsections: [
      { id: 'installation', title: 'Installation' },
      { id: 'authentication', title: 'Authentication' },
      { id: 'quickstart', title: 'Quick Start' },
    ],
  },
  {
    title: 'Core Concepts',
    subsections: [
      { id: 'builds', title: 'Builds' },
      { id: 'workspaces', title: 'Workspaces' },
      { id: 'projects', title: 'Projects' },
    ],
  },
  {
    title: 'Guides',
    subsections: [
      { id: 'cli', title: 'CLI Usage' },
      { id: 'uploads', title: 'File Uploads' },
      { id: 'configuration', title: 'Configuration' },
    ],
  },
];

const SDKDocs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [activeSection, setActiveSection] = useState('installation');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const installation = `pip install buildeiro`;

  const usageExample = `#!/usr/bin/env python3
import buildeiro

# Authenticate
buildeiro login client_id client_secret

# Upload build
buildeiro upload --build config.json --files build.zip \\
  --workspace my-workspace --project my-project`;

  return (
    <div className="min-h-screen bg-white flex">
      {/* Mobile Menu Button */}
      <div className="md:hidden fixed top-4 left-4 z-50">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="p-2 bg-white rounded-lg shadow-lg"
        >
          {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
        </button>
      </div>

      {/* Lateral Menu */}
      <aside
        className={`
        md:sticky top-0 h-screen flex-shrink-0 bg-white w-64 border-r border-gray-200 
        fixed left-0 overflow-y-auto
        transform transition-transform duration-200 ease-in-out z-40
        ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}
        ${isMobile ? 'shadow-xl' : ''}
        md:translate-x-0
      `}
      >
        <div className="p-6">
          <h2 className="text-xl font-bold text-navy-900 mb-6">Documentation</h2>
          <nav className="space-y-6">
            {sections.map((section) => (
              <div key={section.title} className="space-y-2">
                <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider">
                  {section.title}
                </h3>
                <ul className="space-y-2">
                  {section.subsections.map((subsection) => (
                    <li key={subsection.id}>
                      <button
                        onClick={() => {
                          setActiveSection(subsection.id);
                          if (isMobile) setIsMenuOpen(false);
                        }}
                        className={`
                          w-full text-left px-2 py-1 rounded-lg text-sm
                          ${
                            activeSection === subsection.id
                              ? 'bg-green-50 text-green-600 font-medium'
                              : 'text-gray-600 hover:bg-gray-50'
                          }
                        `}
                      >
                        {subsection.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 min-w-0">
        <div className="max-w-7xl mx-auto px-6 py-12">
          <header className="mb-12">
            <h1 className="text-5xl font-bold text-navy-900 mb-4">Buildeiro SDK</h1>
            <p className="text-xl text-gray-600">
              Official Python SDK for managing build uploads and releases
            </p>
          </header>

          <div className="space-y-12">
            {/* Quick Start */}
            <section id="quickstart">
              <h2 className="text-3xl font-bold text-navy-900 mb-6">Quick Start</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 rounded-xl p-6">
                  <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-4">
                    <Terminal className="w-8 h-8 text-green-400" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Installation</h3>
                  <div className="bg-gray-900 rounded-lg p-4">
                    <code className="text-white font-mono">{installation}</code>
                  </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-4">
                    <Key className="w-8 h-8 text-green-400" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Authentication</h3>
                  <p className="text-gray-600">
                    Obtain your client ID and secret from the dashboard to authenticate the SDK.
                  </p>
                </div>
              </div>
            </section>

            {/* Example Usage */}
            <section id="cli">
              <h2 className="text-3xl font-bold text-navy-900 mb-6">Example Usage</h2>
              <div className="bg-gray-900 rounded-xl p-6">
                <pre className="text-white font-mono overflow-x-auto">{usageExample}</pre>
              </div>
            </section>

            {/* Features */}
            <section id="features">
              <h2 className="text-3xl font-bold text-navy-900 mb-6">Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[
                  {
                    icon: <Upload className="w-8 h-8 text-green-400" />,
                    title: 'Concurrent Uploads',
                    description:
                      'Upload multiple build artifacts simultaneously with progress tracking',
                  },
                  {
                    icon: <Command className="w-8 h-8 text-green-400" />,
                    title: 'CLI Interface',
                    description:
                      'Simple command-line interface for easy integration with CI/CD pipelines',
                  },
                  {
                    icon: <AlertCircle className="w-8 h-8 text-green-400" />,
                    title: 'Error Handling',
                    description:
                      'Robust error handling with automatic retries and detailed logging',
                  },
                ].map((feature, index) => (
                  <div key={index} className="bg-gray-50 rounded-xl p-6">
                    <div className="w-16 h-16 bg-green-50 rounded-lg flex items-center justify-center mb-4">
                      {feature.icon}
                    </div>
                    <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                ))}
              </div>
            </section>

            {/* Configuration */}
            <section id="configuration">
              <h2 className="text-3xl font-bold text-navy-900 mb-6">Configuration</h2>
              <div className="bg-gray-50 rounded-xl p-6">
                <table className="w-full">
                  <thead>
                    <tr className="text-left">
                      <th className="pb-4 font-bold">Option</th>
                      <th className="pb-4 font-bold">Description</th>
                      <th className="pb-4 font-bold">Default</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600">
                    <tr>
                      <td className="py-2 font-mono">--base-url</td>
                      <td>API endpoint URL</td>
                      <td>http://localhost:8080</td>
                    </tr>
                    <tr>
                      <td className="py-2 font-mono">--workspace</td>
                      <td>Workspace identifier</td>
                      <td>Required</td>
                    </tr>
                    <tr>
                      <td className="py-2 font-mono">--project</td>
                      <td>Project identifier</td>
                      <td>Required</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SDKDocs;
