import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import OnboardingFlow from './features/onboarding/OnboardingFlow';
import './App.css';
import LandingPage from './features/landingpage/LandingPage';
import Dashboard from './features/dashboard/Dashboard';
import ProjectDashboard from './features/project/ProjectDashboard';

import ProfilePage from './features/profile/Profile';
import BuildListPage from './features/builds/BuildListPage';
import LoginPage from './features/login/Login';
import WorkspaceMembership from './features/workspace/WorkspaceMembership';
import Documentation from './features/documentation/Documentation';
import PricingPage from './features/pricing/Pricing';
import { SlideOverProvider } from './ui/SlideOver/SlideOver';
import { Layout } from './ui/Layout/Layout';

// Interface for the ProtectedRoute props
interface ProtectedRouteProps {
  element: React.ReactElement;
  withLayout?: boolean;
}

// Component to wrap routes that need the layout
const LayoutWrapper: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  return <Layout>{element}</Layout>;
};

// Protected route component that optionally applies the layout
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, withLayout = true }) => {
  // Here you would typically check for authentication
  const isAuthenticated = true; // Replace with actual auth check

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return withLayout ? <LayoutWrapper element={element} /> : element;
};

const App: React.FC = () => {


  return (
    <SlideOverProvider>
      <Router>
        <Routes>
          {/* Public routes - no layout */}
          <Route path="/" element={<LayoutWrapper element={<LandingPage />} />} />
          <Route path="/documentation" element={<LayoutWrapper element={<Documentation />} />} />
          <Route path="/pricing" element={<LayoutWrapper element={<PricingPage />} />} />

          {/* Protected routes with layout */}
          <Route path="/" element={<ProtectedRoute element={<LandingPage />} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<ProtectedRoute element={<OnboardingFlow />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<ProfilePage />} />} />
          <Route path="/workspace" element={<ProtectedRoute element={<Dashboard />} />} />
          {/* Workspace and Project routes - all with layout */}
          <Route
            path="/workspace/:workspaceSlug/membership"
            element={<ProtectedRoute element={<WorkspaceMembership />} />}
          />
          <Route
            path="/workspace/:workspaceSlug/projects"
            element={<ProtectedRoute element={<ProjectDashboard />} />}
          />
          <Route
            path="/workspace/:workspaceSlug/projects/:projectSlug/builds"
            element={<ProtectedRoute element={<BuildListPage />} />}
          />
        </Routes>
      </Router>
    </SlideOverProvider>

  );
};

export default App;
