export type BuildPlatform =
  | 'JENKINS'
  | 'CLOUD_BUILD'
  | 'SELF_HOSTED'
  | 'GITHUB'
  | 'GITLAB'
  | 'BITBUCKET'
  | 'AZURE_DEVOPS'
  | 'CIRCLE_CI'
  | 'TRAVIS_CI'
  | 'TEAMCITY'
  | 'LOCAL'
  | 'OTHER';

export const BUILD_PLATFORMS: BuildPlatform[] = [
  'JENKINS',
  'CLOUD_BUILD',
  'SELF_HOSTED',
  'GITHUB',
  'GITLAB',
  'BITBUCKET',
  'AZURE_DEVOPS',
  'CIRCLE_CI',
  'TRAVIS_CI',
  'TEAMCITY',
  'LOCAL',
  'OTHER',
];
