import { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Label } from '@/components/ui/label';

interface SimpleSelectProps<T extends string> {
  options: T[];
  value?: T;
  onValueChange?: (value: T) => void;
  label?: string;
  placeholder?: string;
  formatLabel?: (value: T) => string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  className?: string;
}

const defaultFormatLabel = (value: string): string => {
  return value
    .split('_')
    .map((word) => word)
    .join(' ');
};

export function SimpleSelect<T extends string>({
  options,
  value,
  onValueChange,
  label,
  placeholder = 'Select an option',
  formatLabel = defaultFormatLabel,
  disabled = false,
  error,
  required = false,
  className = '',
}: SimpleSelectProps<T>) {
  const [open, setOpen] = useState(false);

 
  return (
    <div className={`space-y-2 w-full ${className}`}>
      {label && (
        <Label className="text-sm flex items-center gap-1">
          {label}
          {required && <span className="text-red-500">*</span>}
        </Label>
      )}

      <Select
        value={value}
        onValueChange={onValueChange}
        open={open}
        onOpenChange={setOpen}
        disabled={disabled}
      >
        <SelectTrigger
          className={`w-full ${error ? 'border-red-500 focus:ring-red-200' : ''}`}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>

        <SelectContent
          className="w-[var(--radix-select-trigger-width)] z-[99]"
          position="popper"
        >
          <ScrollArea className="h-[200px] overflow-y-auto p-1">
            <SelectGroup>
              {options.map((option) => (
                <SelectItem
                  key={option}
                  value={option}
                  className="cursor-pointer py-2 px-2 rounded-md focus:bg-gray-100 focus:text-gray-900"
                >
                  {formatLabel(option)}
                </SelectItem>
              ))}
            </SelectGroup>
          </ScrollArea>
        </SelectContent>
      </Select>

      {error && (
        <p className="text-sm text-red-500 mt-1">
          {error}
        </p>
      )}
    </div>
  );
}

export default SimpleSelect;