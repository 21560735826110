import React, { useState, useEffect } from 'react';
import { EndpointConfig, TokenService } from '../../lib/token';
import {
  Plus,
  Users,
  FolderGit2,
  HardDrive,
  Loader2,
  X,
  ChevronRight,
  Settings,
  Star,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Workspace {
  workspaceId: string;
  name: string;
  maxUsers: number;
  maxProjects: number;
  maxStorage: number;
  slug: string;
}

// Add new interface for workspace with favorite status
interface WorkspaceWithFavorite extends Workspace {
  isFavorite: boolean;
}

interface WorkspaceCreateData {
  workspaceName: string;
}

interface UseWorkspacesReturn {
  workspaces: WorkspaceWithFavorite[];
  loading: boolean;
  error: string | null;
  createWorkspace: (workspaceData: WorkspaceCreateData) => Promise<boolean>;
  refreshWorkspaces: () => Promise<void>;
  toggleFavorite: (workspaceId: string) => void;
}

const FAVORITES_KEY = 'workspace_favorites';

const useWorkspaces = (): UseWorkspacesReturn => {
  const [workspaces, setWorkspaces] = useState<WorkspaceWithFavorite[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Load favorites from localStorage
  const loadFavorites = (): string[] => {
    const saved = localStorage.getItem(FAVORITES_KEY);
    return saved ? JSON.parse(saved) : [];
  };

  // Save favorites to localStorage
  const saveFavorites = (favorites: string[]) => {
    localStorage.setItem(FAVORITES_KEY, JSON.stringify(favorites));
  };

  const toggleFavorite = (workspaceId: string) => {
    const favorites = loadFavorites();
    const newFavorites = favorites.includes(workspaceId)
      ? favorites.filter((id) => id !== workspaceId)
      : [...favorites, workspaceId];

    saveFavorites(newFavorites);

    setWorkspaces((current) =>
      current
        .map((workspace) => ({
          ...workspace,
          isFavorite: newFavorites.includes(workspace.workspaceId),
        }))
        .sort((a, b) => {
          if (a.isFavorite === b.isFavorite) return a.name.localeCompare(b.name);
          return a.isFavorite ? -1 : 1;
        }),
    );
  };

  const fetchWorkspaces = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/user/workspaces`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: Workspace[] = await response.json();
      const favorites = loadFavorites();

      // Add favorite status and sort
      const workspacesWithFavorites = data
        .map((workspace) => ({
          ...workspace,
          isFavorite: favorites.includes(workspace.workspaceId),
        }))
        .sort((a, b) => {
          if (a.isFavorite === b.isFavorite) return a.name.localeCompare(b.name);
          return a.isFavorite ? -1 : 1;
        });

      setWorkspaces(workspacesWithFavorites);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const createWorkspace = async (workspaceData: WorkspaceCreateData): Promise<boolean> => {
    try {
      setLoading(true);
      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/workspace`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getInstance().getAccessToken()}`,
        },
        body: JSON.stringify(workspaceData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      await fetchWorkspaces();
      return true;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  return {
    workspaces,
    loading,
    error,
    createWorkspace,
    refreshWorkspaces: fetchWorkspaces,
    toggleFavorite,
  };
};

const WorkspaceCard: React.FC<{
  workspace: WorkspaceWithFavorite;
  onToggleFavorite: (workspaceId: string) => void;
}> = ({ workspace, onToggleFavorite }) => {
  const navigate = useNavigate();

  return (
    <div className="group relative bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 p-6">
      <div className="absolute inset-0 bg-green-400/0 group-hover:bg-green-400/5 rounded-xl transition-colors duration-200" />

      <div className="relative">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-3">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggleFavorite(workspace.workspaceId);
              }}
              className="p-1 rounded-full hover:bg-gray-100 transition-colors"
            >
              <Star
                className={`w-5 h-5 transition-colors ${
                  workspace.isFavorite
                    ? 'fill-yellow-400 text-yellow-400'
                    : 'text-gray-300 hover:text-gray-400'
                }`}
              />
            </button>
            <h3 className="text-xl font-bold text-navy-900 group-hover:text-green-600 transition-colors">
              {workspace.name}
            </h3>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/workspace/${workspace.slug}/membership`);
              }}
              className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50 transition-all"
            >
              <Settings className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="space-y-4 mb-6">
          <div className="flex items-center gap-3 text-gray-600">
            <Users className="w-4 h-4" />
            <span className="text-sm">{workspace.maxUsers} Users</span>
          </div>
          <div className="flex items-center gap-3 text-gray-600">
            <FolderGit2 className="w-4 h-4" />
            <span className="text-sm">{workspace.maxProjects} Projects</span>
          </div>
          <div className="flex items-center gap-3 text-gray-600">
            <HardDrive className="w-4 h-4" />
            <span className="text-sm">
              {(workspace.maxStorage / 1024 / 1024 / 1024).toFixed(0)} GB Storage
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between pt-4 border-t border-gray-100">
          <button
            onClick={() => navigate(`/workspace/${workspace.slug}/projects`)}
            className="text-sm text-gray-600 group-hover:text-green-600 transition-colors flex items-center gap-2"
          >
            View projects
            <ChevronRight className="w-4 h-4 text-gray-400 group-hover:text-green-400 transform group-hover:translate-x-1 transition-all" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Dashboard: React.FC = () => {
  const { workspaces, loading, error, createWorkspace, toggleFavorite } = useWorkspaces();
  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState<boolean>(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState<string>('');

  const handleCreateWorkspace = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!newWorkspaceName.trim()) return;

    const success = await createWorkspace({
      workspaceName: newWorkspaceName,
    });

    if (success) {
      setNewWorkspaceName('');
      setIsCreatingWorkspace(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <Loader2 className="w-8 h-8 text-green-400 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="bg-red-50 text-red-700 px-6 py-4 rounded-lg">
          <p className="text-sm">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-navy-900">Workspaces</h1>
            <p className="text-gray-600 mt-2">Manage your organization's workspaces and projects</p>
          </div>
          <button
            onClick={() => setIsCreatingWorkspace(true)}
            className="bg-green-400 text-white px-6 py-2 rounded-lg hover:bg-green-500 transition-colors flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            Create Workspace
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {workspaces.map((workspace) => (
            <WorkspaceCard
              key={workspace.workspaceId}
              workspace={workspace}
              onToggleFavorite={toggleFavorite}
            />
          ))}
        </div>
      </div>

      {isCreatingWorkspace && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-navy-900">Create New Workspace</h2>
              <button
                onClick={() => setIsCreatingWorkspace(false)}
                className="text-gray-400 hover:text-gray-600 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <form onSubmit={handleCreateWorkspace}>
              <div className="mb-6">
                <label
                  htmlFor="workspace-name"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Workspace Name
                </label>
                <input
                  id="workspace-name"
                  type="text"
                  value={newWorkspaceName}
                  onChange={(e) => setNewWorkspaceName(e.target.value)}
                  placeholder="Enter workspace name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-300 focus:border-green-400 transition-colors"
                  required
                />
              </div>
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setIsCreatingWorkspace(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-400 text-white px-6 py-2 rounded-lg hover:bg-green-500 transition-colors"
                >
                  Create Workspace
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
