import { useState } from 'react';
import { TokenResponse, EndpointConfig } from '../../../lib/token';
import { BackendError } from '@/models/errorTypes';
import { createBackendError } from '@/ui/Errors/errorFunctions';

interface UseAuthReturn {
  getAccessToken: (clientId: string, clientSecret: string) => Promise<TokenResponse>;
  isLoading: boolean;
  authorizationError: BackendError | null;
  tokenResponse: TokenResponse | null;
  clearError: () => void;
  isVerificationRequired?: boolean;
  isVerificationTokenExpired?: boolean;
}

export const useAuth = (): UseAuthReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [authorizationError, setError] = useState<BackendError | null>(null);
  const [tokenResponse, setTokenResponse] = useState<TokenResponse | null>(null);

  const clearError = () => setError(null);

  const getAccessToken = async (clientId: string, clientSecret: string): Promise<TokenResponse> => {
    setIsLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append('client_id', clientId);
      formData.append('client_secret', clientSecret);
      formData.append('grant_type', 'client_credentials');

      const response = await fetch(`${EndpointConfig.baseUrl}/api/v1/login/oauth/access_token`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        // Handle structured error response
        const backendError: BackendError = createBackendError(data, response);
        setError(backendError);
        throw backendError;
      }

      setTokenResponse(data);
      return data;
    } catch (error) {
      // Handle unexpected errors
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (error instanceof Error && !(error as any).type) {
        const unexpectedError: BackendError = {
          type: 'app-store/server-error',
          status: 500,
          title: 'Unexpected Error',
          details: error.message || 'An unexpected error occurred during authentication',
        };
        setError(unexpectedError);
        throw unexpectedError;
      }
      // If it's already a BackendError, just re-throw it
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getAccessToken,
    isLoading,
    authorizationError,
    tokenResponse,
    clearError,
  };
};
