export interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

export const EndpointConfig = {
  baseUrl: import.meta.env.VITE_BASE_URL as string,
};

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

interface StoredToken extends TokenResponse {
  expires_at: number;
}

// TokenEvents.ts
export class TokenEvents {
  private static instance: TokenEvents;
  private listeners: Set<() => void> = new Set();

  private constructor() {}

  static getInstance(): TokenEvents {
    if (!TokenEvents.instance) {
      TokenEvents.instance = new TokenEvents();
    }
    return TokenEvents.instance;
  }

  addListener(listener: () => void) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  emit() {
    this.listeners.forEach((listener) => listener());
  }
}

// TokenService.ts
export class TokenService {
  private static instance: TokenService;
  public readonly storageKey = 'auth_tokens';
  private refreshTimeout: NodeJS.Timeout | null = null;
  private readonly baseUrl: string;
  private tokenEvents: TokenEvents;

  private constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.tokenEvents = TokenEvents.getInstance();
  }

  static getInstance(): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService(EndpointConfig.baseUrl);
    }
    return TokenService.instance;
  }

  public getStoredTokens(): StoredToken | null {
    const storedData = localStorage.getItem(this.storageKey);
    if (!storedData) return null;

    try {
      const tokens: StoredToken = JSON.parse(storedData);
      return tokens;
    } catch {
      return null;
    }
  }

  getAccessToken(): string | null {
    const tokens = this.getStoredTokens();
    if (!tokens) return null;

    if (Date.now() >= tokens.expires_at) {
      this.refreshAccessToken();
      return null;
    }

    return tokens.access_token;
  }

  isAuthenticated(): boolean {
    const tokens = this.getStoredTokens();
    return !!tokens && Date.now() < tokens.expires_at;
  }

  async refreshAccessToken(): Promise<TokenResponse | null> {
    const tokens = this.getStoredTokens();
    if (!tokens?.refresh_token) return null;

    try {
      const response = await fetch(`${this.baseUrl}/api/v1/login/oauth/access_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: tokens.refresh_token,
        }),
      });

      if (!response.ok) throw new Error('Failed to refresh token');

      const newTokens: TokenResponse = await response.json();
      this.setTokens(newTokens);
      return newTokens;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      this.clearTokens();
      return null;
    }
  }

  setTokens(tokens: TokenResponse): void {
    const expiresAt = Date.now() + tokens.expires_in * 1000;
    const storedTokens: StoredToken = {
      ...tokens,
      expires_at: expiresAt,
    };

    localStorage.setItem(this.storageKey, JSON.stringify(storedTokens));
    this.scheduleTokenRefresh(tokens.expires_in);
    this.tokenEvents.emit(); // Emit event when tokens are set
  }

  private scheduleTokenRefresh(expiresIn: number): void {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    const refreshTime = (expiresIn - 60) * 1000;
    if (refreshTime <= 0) return;

    this.refreshTimeout = setTimeout(() => {
      this.refreshAccessToken();
    }, refreshTime);
  }

  clearTokens(): void {
    localStorage.removeItem(this.storageKey);
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
    this.tokenEvents.emit(); // Emit event when tokens are cleared
  }
}
