
import { BackendError } from '../../../models/errorTypes';
import { errorMessages } from "../errorFunctions";
import ErrorAlert from '../ErrorAlert/ErrorAlert';

interface ErrorMessageProps {
  error: BackendError;
  onDismiss?: () => void;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, onDismiss }) => {
  const getMessage = (error: BackendError): string => {
    // Check if we have a specific message for this error type
    const message = errorMessages[error.type];

    if (message) {
      return message;
    }

    // If no specific message found, use the error title/detail or fall back to default
    if (error.details) {
      return error.details;
    }

    if (error.title) {
      return error.title;
    }

    return errorMessages.default;
  };

  return <ErrorAlert message={getMessage(error)} onDismiss={onDismiss && onDismiss} />;
};
