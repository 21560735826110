import { useState } from 'react';
import { ChevronRight, Mail } from 'lucide-react';
import { FormData } from '@/models/onboarding';
import { ProgressSteps } from './components';
import { useRegister } from './hooks/useRegister';
import AccountStep from './components/AccountStep';
import { ErrorMessage } from '@/ui/Errors/ErrorMessage/ErrorMessage';

const initialFormData: FormData = {
  email: 'team@gameforgelabs.com',
  password: 'testerA$1',
  displayName: 'AA a',
};

export default function OnboardingFlow() {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [showVerification, setShowVerification] = useState(false);
  const { createAccount, isLoading: isRegistering, error: registerError } = useRegister();

  const handleContinue = async () => {
    if (!formData.email || !formData.password || !formData.displayName) {
      return;
    }

    const result = await createAccount(formData.email, formData.password, formData.displayName);

    if (result.success) {
      setShowVerification(true);
    }
  };

  const renderEmailVerification = () => (
    <div className="rounded-lg bg-green-50 p-6">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          <Mail className="h-6 w-6 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-lg font-semibold text-gray-900">Verify your email address</h3>
          <div className="mt-2">
            <p className="text-base text-gray-600">
              We've sent a verification email to{' '}
              <span className="font-medium">{formData.email}</span>. Please check your inbox and
              verify your account to continue.
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-50">
      <main className="pb-16 pt-16">
        <div className="mx-auto max-w-2xl px-6">
          <div className="rounded-2xl bg-white p-8 shadow-xl">
            <ProgressSteps currentStep={1} />

            <div className="space-y-6">
              {showVerification ? (
                renderEmailVerification()
              ) : (
                <>
                  <AccountStep
                    formData={formData}
                    onChange={(field, value) => setFormData({ ...formData, [field]: value })}
                  />
                  {registerError && (
                    <div className="mt-6">
                      <ErrorMessage error={registerError} />
                    </div>
                  )}
                </>
              )}
            </div>

            {!showVerification && (
              <div className="mt-8">
                <button
                  onClick={handleContinue}
                  disabled={isRegistering}
                  className={`
                    flex items-center justify-center w-full rounded-lg bg-green-400 px-8 py-3
                    font-medium text-white transition-all duration-150 hover:bg-green-500
                    focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2
                    disabled:opacity-50 disabled:cursor-not-allowed
                  `}
                  type="button"
                >
                  {isRegistering ? (
                    'Registering...'
                  ) : (
                    <>
                      Continue
                      <ChevronRight className="ml-2 h-5 w-5" />
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
